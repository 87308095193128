import { Injectable } from '@angular/core';
import {IItemStorageService} from "../storage/item/item-storage.service.interface";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {WorkspaceDto} from "../../models/dto/workspace/workspaceDto";
import {BehaviorSubject, Observable} from "rxjs";
import {PopulationService} from "../population/population.service";
import {environment} from "../../environments/environment";
import {D} from "@angular/cdk/keycodes";
import {AuthenticationService} from "../user/authentication.service";
import {PopulationDto} from "../../models/dto/population/populationDto";

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private readonly STORAGEKEY = "workspace"
  private _workspace: BehaviorSubject<WorkspaceDto> = new BehaviorSubject({});
  public workspace: Observable<WorkspaceDto> = this._workspace.asObservable();
  private currentWorkspace: WorkspaceDto | undefined;

  constructor(
    private storage: IItemStorageService,
    private client: HttpClient,
    private popService: PopulationService,

    private authService: AuthenticationService

  ) {
    // if (this.authService.isUserAuthenticated()){
    //   this.init();
    // }
    // this.authService.authenticationChanged.subscribe( res => {
    //   if (!res) {
    //     this._workspace.next({});
    //   } else {
    //     this.init();
    //   }
    // })
  }

  public setWorkspace(dto: WorkspaceDto) {
    this._workspace.next(dto);
  }

  private init() {
    // this._getWorkspace();
    // this.popService.population.subscribe(res => {
    //   if (res && this.currentWorkspace && this.currentWorkspace.settings && res.id !== this.currentWorkspace.settings.population?.id) {
    //     this.currentWorkspace.settings.population = res;
    //     this.switchWorkspace(this.currentWorkspace);
    //   }
    // })
  }

  private _getWorkspace() {
      this._initializeWorkspace();
  }

  private _initializeWorkspace() {
    this.getPopulationWorkspace().subscribe(res => {

      if (res.settings) {
        this._workspace.next(res);
        this.currentWorkspace = res;
        if (res.settings.population) {
          this.popService.select(res.settings.population!);
        }


      }
    })
  }

  switchWorkspace(dto: WorkspaceDto) {
    // this.updatePopulationWorkspace(dto).subscribe(res => {
    //   this._workspace.next(res);
    //   this.currentWorkspace = res;
    // })
  }

  getPopulationWorkspace(): Observable<WorkspaceDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.workspace.get}`;
    return this.client.get<WorkspaceDto>(url);

  }

  updatePopulationWorkspace(dto: WorkspaceDto): Observable<WorkspaceDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.workspace.get}`;
    return this.client.put<WorkspaceDto>(url, dto);

  }

  switchPopulation($event: PopulationDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.workspace.get}`;
    return this.client.patch(url, $event);
  }

  updateTheme(dto: WorkspaceDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.workspace.get}/theme`;
    const obs = this.client.patch(url, dto);
    obs.subscribe( {
      next: (value: WorkspaceDto) => {
        this._workspace.next(value);
      },
      error: (value: HttpErrorResponse) => {
        console.log(value);
      }
    })
    // return this.client.patch(url, dto);
  }
}
