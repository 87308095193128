<h1 mat-dialog-title>Update Encounter Details</h1>
<div mat-dialog-content *ngIf="data.encounter">
  <div class="encounter-updates">
    <mat-stepper orientation="vertical" [linear]="false" #stepper>
      <mat-step>
        <ng-template matStepLabel>Encounter Date</ng-template>
        <div class="submission-date-container">
          <mat-form-field color="accent" class="full-width">
            <mat-label>Enter a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="data.encounter.dateTime!">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Encounter Location</ng-template>
        <h3>Encounter Location</h3>
        <h4>{{data.encounter.location!.name!}}</h4>
        <h4>Latitude: {{data.encounter.location!.latitude!}}</h4>
        <h4>Longitude: {{data.encounter.location!.longitude!}}</h4>
        <button mat-stroked-button color="accent" (click)="updateLocation()" class="full-width">Update Location</button>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Predation Event</ng-template>
        <app-submission-prey *ngIf="population !== undefined && population!.id !== undefined"
                             [population]="population"
                             [predationEvent]="data.encounter.predationEvent ?? false"
                             [predationTargets]="data.encounter.predationTargets ?? []"
                             (predationEventUpdated)="updatePredationEvent($event)"></app-submission-prey>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Encounter Behaviors</ng-template>
        <app-submission-behavior [population]="population" [selectedBehaviors]="data.encounter.behaviors" (behaviorsUpdated)="updateBehaviors($event)"></app-submission-behavior>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Encounter Notes</ng-template>
        <mat-form-field class="full-width" color="accent">
          <mat-label>Describe the Encounter</mat-label>
          <textarea matInput placeholder="Group seen moving in the direction of..." [(ngModel)]="data.encounter.description"></textarea>
        </mat-form-field>
      </mat-step>
      <mat-step *ngIf="userRights !== undefined &&  (userRights!.administrator || userRights!.professional)">
        <ng-template matStepLabel>Encounter Contributor</ng-template>
        <app-submission-user [submissionUser]="data.user" (submissionUserUpdated)="data.user = $event"></app-submission-user>
      </mat-step>
      <mat-step *ngIf="userRights !== undefined &&  (userRights!.administrator || userRights!.professional)">
        <ng-template matStepLabel>Encounter Organizations</ng-template>
        <app-update-organizations [encounter]="data.encounter"></app-update-organizations>
      </mat-step>
      <mat-step *ngIf="userRights !== undefined &&  (userRights!.administrator || userRights!.professional)">
        <ng-template matStepLabel>Encounter Population</ng-template>
        <app-update-population-component [submissionUser]="data.user" [encounter]="data.encounter" (populationid)="data.encounter.populationId = $event"></app-update-population-component>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Encounter Complete Status</ng-template>
        <mat-radio-group [(ngModel)]="data.encounter.completeStatus">
          <mat-radio-button [value]="'complete'">Complete</mat-radio-button>
          <mat-radio-button [value]="'incomplete'">Incomplete</mat-radio-button>
          <mat-radio-button [value]="'unknown'">Unknown</mat-radio-button>
        </mat-radio-group>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Set License Information</ng-template>
        <app-submission-limitation [selectedOrganizations]="getOrganizationIds()" (updated)="setEncounterLicense($event)" [selectedLicense]="data.encounter.license"></app-submission-limitation>
      </mat-step>
    </mat-stepper>
  </div>
</div>
<div mat-dialog-actions class="button-row">
  <button mat-button [mat-dialog-close]="data">Confirm Updates</button>
  <button mat-button (click)="onNoClick()">Cancel Updates</button>
</div>

<ng-template #noData>
  Encounter not yet loaded...
</ng-template>
