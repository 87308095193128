<button mat-stroked-button color="accent" *ngIf="markForDel" (click)="toggleMarkForDeletionState()">Finished Deleting</button>
<button mat-stroked-button color="accent" *ngIf="markForSideLabel" (click)="finishSideLabelling()">Choose Side And Finish</button>
<button mat-button color="accent" [matMenuTriggerFor]="menu"  *ngIf="encounter && encounterUser && canEdit">
  <mat-icon>settings</mat-icon> Edit Encounter
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="addImages()">
    <span>Add Images</span>
  </button>

  <button *ngIf="higherPrivileges" mat-menu-item (click)="reassociate()" matTooltipPosition="left" matTooltip="Ensure that every confirmed annotation is correctly associated with the individual profile">
    <span>Ensure Annotation Animal Association</span>
  </button>

  <button *ngIf="higherPrivileges"  mat-menu-item (click)="assignSides()"  matTooltipPosition="left" matTooltip="Send images to automatically assign the visible animal side">
    <span>Auto Assign Side To Images</span>
  </button>

  <button mat-menu-item (click)="updateDetails()">
    <span>Update Encounter Details</span>
  </button>

  <button *ngIf="higherPrivileges"  mat-menu-item (click)="requeue()" matTooltipPosition="left" matTooltip="Send all items without annotations back to the model for annotation">
    <span>Requeue Unfinished</span>
  </button>

  <button *ngIf="higherPrivileges"  mat-menu-item (click)="requeueAll()" matTooltipPosition="left" matTooltip="Send all items back to the model for annotation">
    <span>Requeue All Items</span>
  </button>
  <button *ngIf="higherPrivileges"  mat-menu-item (click)="removeModelPredictions()" matTooltipPosition="left" matTooltip="Remove all model predictions from all images">
    <span>Remove Model Predictions</span>
  </button>
  <button mat-menu-item (click)="tryPersistMissing()" matTooltipPosition="left" matTooltip="Missing images? Click here to try and find them.">
    <span>Try and Persist Missing Images</span>
  </button>

  <button mat-menu-item [matMenuTriggerFor]="bulk"  *ngIf="encounter && encounterUser && canEdit">
    Bulk Actions
  </button>
  <mat-menu #bulk="matMenu">
    <button mat-menu-item (click)="toggleMarkSideApplication()">
      <span>Mark Image Side</span>
    </button>
    <button mat-menu-item (click)="toggleMarkForDeletionState()">
      <span>Mark Images for Deletion</span>
    </button>
  </mat-menu>


  <mat-divider></mat-divider>
  <button *ngIf="higherPrivileges"  mat-menu-item (click)="mergeEncounter()">
    <span>Merge Encounter</span>
  </button>
  <button *ngIf="higherPrivileges" mat-menu-item (click)="updateArchivalStatus()">
    <span style="color: #d50000">Set Archival Status</span>
  </button>
  <button mat-menu-item (click)="updateLicense()">
    <span style="color: #d50000">Change Encounter Visibility / License</span>
  </button>
  <button mat-menu-item (click)="deleteEncounter()">
    <span style="color: #d50000">Delete Encounter</span>
  </button>
</mat-menu>

<div class="submission-loading-container" *ngIf="imageUploadStarted && !imageUploadFinished && files">
  <div class="submission-loading-content">
    <div class="spinner-container" *ngIf="uploadsFinished < files.length">
      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="75" [strokeWidth]="2"  color="accent"></mat-progress-spinner>

    </div>
    <div class="submission-step-text-list">
      <div class="list-item files-list">
        <mat-list class="list-container">
          <mat-list-item *ngFor="let upload of uploads" class="file-list-item">
            <div class="file-list-item-content">
              <span *ngIf="!upload.done && !upload.failed"><mat-progress-spinner mode="determinate" [value]="upload.percentage" diameter="16"></mat-progress-spinner></span>
              <span *ngIf="upload.done"><mat-icon>check</mat-icon></span>
              <span *ngIf="upload.failed"><button mat-button (click)="uploadFile(upload.file)"><mat-icon>restart_alt</mat-icon>Retry Upload</button></span>
              <span>{{upload.name}}</span>
              <mat-error *ngIf="upload.failed">Upload Failed. </mat-error>
            </div>

          </mat-list-item>
        </mat-list>
      </div>

    </div>
    <div class="steps-finished" *ngIf="uploadsFinished == files.length">
      <mat-icon>check</mat-icon>
      <span>All Done!</span>
    </div>
    <div class="steps-finished-confirm"  *ngIf="uploadsFinished == files.length">
      <button mat-raised-button color="primary" (click)="confirmImageUpload()">Confirm</button>
    </div>
  </div>
</div>

<app-area-loading [criteria]="!updating" [title]="'Saving...'"></app-area-loading>
