<div class="header">
  <h2>Permits</h2>
  <button mat-icon-button (click)="startNewPermitAdd()"><mat-icon>add</mat-icon></button>
</div>


<div class="content">
  <h4>Only one permit may be active at a time. Setting one to "Active" will cause the others to be set to "Inactive".</h4>
  <div *ngFor="let permit of permits" class="permit">
    <div class="permit-header">

      <h3 *ngIf="permit.permit.length == 0">New Permit</h3>
      <h3 *ngIf="permit.permit.length > 0"  (click)="startPermitEdit(permit)" [ngClass]="{'isActive': permit.isActive}">{{permit.permit}}</h3>
      <button mat-icon-button color="warn" *ngIf="permit.editing && !permit.newPermit" (click)="delete(permit)"><mat-icon>delete</mat-icon></button>
    </div>
    <div class="permit-content"  (click)="startPermitEdit(permit)">
      <div class="active-marker">
      <span *ngIf="!permit.editing">
        Active: {{permit.isActive}}
      </span>
        <mat-checkbox color="accent" *ngIf="permit.editing" [(ngModel)]="permit.isActive">Active Permit</mat-checkbox>
      </div>
      <div class="permit-number">
        <span *ngIf="!permit.editing">
          Permit / License Number: {{permit.permit}}
        </span>
        <mat-form-field *ngIf="permit.editing" color="accent">
          <mat-label>Permit / License Number:</mat-label>
          <input matInput placeholder="12345...." [(ngModel)]="permit.permit">
        </mat-form-field>
      </div>
      <div class="permit-valid-from">
        <span *ngIf="!permit.editing">
          Valid From: {{dateService.formatDateFromAny(permit.validFrom, false, true, true, true, false)}}
        </span>
        <mat-form-field *ngIf="permit.editing"  color="accent">
          <mat-label>Valid From</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="permit.validFrom">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="permit-valid-to">
        <span *ngIf="!permit.editing">
          Valid To: {{dateService.formatDateFromAny(permit.validTo, false, true, true, true, false)}}
        </span>
        <mat-form-field *ngIf="permit.editing" color="accent">
          <mat-label>Valid To</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="permit.validTo">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>


    </div>
    <div class="button-row" *ngIf="permit.editing">
      <button mat-stroked-button (click)="save(permit)">Save</button>
      <button mat-stroked-button (click)="cancel(permit)">Cancel</button>
    </div>

    <mat-divider></mat-divider>
  </div>
</div>


<app-area-loading [criteria]="!saving" [title]="'Saving Permit...'"></app-area-loading>
