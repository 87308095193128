<div mat-dialog-title>
  <h3>Set Encounter Archival Status</h3>

</div>
<div mat-dialog-content class="content-container">
  <p>This indicates that this encounter has already been exported and archived by some other system.</p>
  <p>This function is here to prevent duplicates in other systems.</p>
  <div class="content" *ngIf="data.encounter">
    <div class="archive-confirmed" *ngIf="archived">
      <p>This encounter has been marked as archived by {{data.encounter.archivedBy?.firstName}} {{data.encounter.archivedBy?.lastName}} on {{dateService.formatDateFromAny(data.encounter.archivedOn, false, true, true, true, false)}}</p>

    </div>
    <div class="archive-update">
      <mat-checkbox [(ngModel)]="data.encounter.archived"> Mark As Archived
      </mat-checkbox>
    </div>

  </div>
</div>

<div mat-dialog-actions class="button-row">
  <button mat-stroked-button color="accent" (click)="submit()" >Confirm</button>
  <button mat-stroked-button color="accent" (click)="onNoClick()" >Cancel</button>

</div>
