import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {JoinRequestDto} from "../../../../../models/dto/organization/organizationJoinRequestDto";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {UserOrganizationService} from "../../../../../services/user/organization/user-organization.service";
import {
  OrganizationJoinRequestComponent
} from "../../../organization/organization-profile/organization-join-request/organization-join-request.component";

@Component({
  selector: 'app-organization-join-request-response',
  templateUrl: './organization-join-request-response.component.html',
  styleUrls: ['./organization-join-request-response.component.scss']
})
export class OrganizationJoinRequestResponseComponent {
  constructor(
    public dialogRef: MatDialogRef<OrganizationJoinRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JoinRequestDto,
    public workspaceService: WorkspaceService,
    private log: ILoggingService,
    public organizationservice: UserOrganizationService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(accept: boolean) {
    if (accept) {
      this.data.approved = true;
      this.data.rejected = false;
    } else {
      this.data.approved = false;
      this.data.rejected = true;
    }
    this.dialogRef.close(this.data);
  }

}
