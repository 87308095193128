<h1 mat-dialog-title>Request Membership for {{this.data.itemName}}</h1>

<div mat-dialog-content>
  <h5>Your request will be forwarded to the administrators. They will then have the opportunity to review your request and either accept or deny it.</h5>
  <mat-form-field>
    <mat-label>Message to the Administrators</mat-label>
    <textarea matInput [(ngModel)]="data.requestingMessage"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-buttons">
  <button mat-raised-button (click)="submit()" cdkFocusInitial color="accent">Submit Request</button>
  <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
</div>
