import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PopulationService} from "../../../../../services/population/population.service";
import {Observable} from "rxjs";
import {PopulationSettingsDto} from "../../../../../models/dto/population/populationSettingsDto";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {PopulationPrey} from "../../../../../models/dto/population/populationPrey";

@Component({
  selector: 'app-population-settings-component',
  templateUrl: './population-settings-component.component.html',
  styleUrls: ['./population-settings-component.component.scss']
})
export class PopulationSettingsComponentComponent implements OnInit, OnChanges {
  @Input() population: PopulationDto | undefined;
  @Input() canLoad: boolean = false;

  public settingsObs: Observable<PopulationSettingsDto> | undefined;
  public settings: PopulationSettingsDto | undefined;
  public prey: Array<PopulationPrey> | undefined;
  public fetched: boolean = false;
  public editing: Map<string, boolean> | undefined;
  adding: boolean = false;
  newPrey: PopulationPrey | undefined;

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.canLoad) {
      if (this.population !== undefined && this.population.id !== undefined) {
        this.populationService.getPopulationSettings(this.population.id)
          .subscribe(res => {
            this.settings = res;
          })
        this.initializePrey();
      }
    }

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["canLoad"] !== undefined) {
      if (changes["canLoad"]) {
        this.ngOnInit();
      }
    }
  }



  updateMainSettings(population: PopulationDto) {
    this.populationService
      .updatePopulationDetails(population.id!, population)
      .subscribe({
        next: (population: PopulationDto) => {
        this.log.info("Population successfully updated", true)
      },
        error: (error: HttpErrorResponse) => {
        this.log.error(`Could not update population: ${error.message}`)
        }
      })
  }

  updateSettings(settings: PopulationSettingsDto) {
    this.populationService
      .updatePopulationSettings(this.population!.id!, settings)
      .subscribe({
        next: (population: PopulationSettingsDto) => {
          this.log.info("Population successfully updated", true)
        },
        error: (error: HttpErrorResponse) => {
          this.log.error(`Could not update population: ${error.message}`)
        }
      })
  }

  public addPreyTarget() {
    this.newPrey = {
      displayName: '',
      populationId: this.population?.id
    }
    this.adding = true;
  }

  private initializePrey() {
    this.populationService
      .getPopulationPrey(this.population!.id!)
      .subscribe({
        next: (value: Array<PopulationPrey>) => {
          this.prey = value;
          this.fetched = true;
          this.initMap();

        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not fetch prey: ${value.message}`);
          this.fetched = true;
        }
      })
  }

  private initMap() {
    this.editing = new Map<string, boolean>();
    for (let i of this.prey!) {
      this.editing.set(i.id!, false);
    }
  }

  createTarget() {
    this.populationService
      .addPopulationPrey(this.population!.id!, this.newPrey!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} added`);
          this.adding = false;
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create prey: ${value.message}`);
          this.adding = false;
          this.initializePrey();
        }
      })
  }

  cancelAdding() {
    this.adding = false;
  }

  activateAdding(id: string) {
    for (let i of this.prey!) {
      this.editing!.set(i.id!, false);
    }
    this.editing!.set(id, true);
  }

  updateTarget(p: PopulationPrey) {
    this.populationService
      .updatePopulationPrey(this.population!.id!, p)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} updated`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not update prey: ${value.message}`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        }
      })
  }

  deleteTarget(p: PopulationPrey) {
    this.populationService
      .deletePopulationPrey(this.population!.id!, p.id!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${p.displayName} deleted`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not delete prey: ${value.message}`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        }
      })
  }


  protected readonly Set = Set;
}
