import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {GlobalModule} from "../global/global.module";
import {AuthenticationService} from "../../services/user/authentication.service";
import { UserHomeComponent } from './user-home/user-home.component';
import {
  PasswordConfirmationValidatorService
} from "../../services/user/validators/password-confirmation-validator.service";
import {NgxJdenticonModule} from "ngx-jdenticon";
import {MatBadgeModule} from "@angular/material/badge";
import {UserContentService} from "../../services/user/content/user-content.service";
import { ManagementComponent } from './management/management.component';
import {UserManagementService} from "../../services/user/management/user-management.service";
import {MatExpansionModule} from "@angular/material/expansion";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {RouterModule} from "@angular/router";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {AdministrationComponent} from "./administration/administration.component";
import {
  PopulationMembersComponentComponent
} from "./administration/populations/population-members-component/population-members-component.component";
import {
  OrganizationSettingsComponentComponent
} from "./administration/organizations/organization-settings-component/organization-settings-component.component";
import {
  OrganizationMembersComponentComponent
} from "./administration/organizations/organization-members-component/organization-members-component.component";
import {
  PopulationSettingsComponentComponent
} from "./administration/populations/population-settings-component/population-settings-component.component";
import {FinPrintModule} from "../fin-print/fin-print.module";
import { PopulationPreyComponent } from './administration/populations/population-prey/population-prey.component';
import { PopulationContributorsComponent } from './administration/populations/population-contributors/population-contributors.component';
import { PopulationContributorEditComponent } from './administration/populations/population-contributors/population-contributor-edit/population-contributor-edit.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { PopulationDescriptionComponent } from './administration/populations/population-description/population-description.component';
import { MembershipInvitationComponent } from './administration/populations/membership-invitation/membership-invitation.component';
import { PopulationAdministrationComponent } from './administration/populations/population-administration/population-administration.component';
import { SinglePopulationAdministrationComponent } from './administration/populations/population-administration/single-population-administration/single-population-administration.component';
import { OrganizationCreationDialogComponent } from './administration/organizations/organization-creation-dialog/organization-creation-dialog.component';
import {
  OrganizationAdministrationComponent
} from "./administration/organizations/organization-administration/organization-administration.component";
import {
  SingleOrganizationAdministrationComponent
} from "./administration/organizations/organization-administration/single-organization-administration/single-organization-administration.component";
import { OrganizationUserAddDialogComponent } from './administration/organizations/organization-user-add-dialog/organization-user-add-dialog.component';
import { UserProfileImageComponent } from './user-home/user-profile-image/user-profile-image.component';
import { UserProfileImageUpdateDialogComponent } from './user-home/user-profile-image/user-profile-image-update-dialog/user-profile-image-update-dialog.component';
import { PlatformAdministrationComponent } from './administration/platform-administration/platform-administration.component';
import { PopulationCreationManagementComponent } from './administration/platform-administration/population-creation-management/population-creation-management.component';
import { PopulationCreationApplicationComponent } from './administration/platform-administration/population-creation-management/population-creation-application/population-creation-application.component';
import { PopulationBannerComponent } from './administration/populations/population-banner/population-banner.component';
import { PopulationBannerUpdateDialogComponent } from './administration/populations/population-banner/population-banner-update-dialog/population-banner-update-dialog.component';
import { OrganizationProfileComponent } from './organization/organization-profile/organization-profile.component';
import { OrganizationProfilePictureComponent } from './organization/organization-profile/organization-profile-picture/organization-profile-picture.component';
import { OrganizationProfilePictureUpdateDialogComponent } from './organization/organization-profile/organization-profile-picture/organization-profile-picture-update-dialog/organization-profile-picture-update-dialog.component';
import { OrganizationProfileMemberPreviewComponent } from './organization/organization-profile/organization-profile-member-preview/organization-profile-member-preview.component';
import { OrganizationProfilePopulationPreviewComponent } from './organization/organization-profile/organization-profile-population-preview/organization-profile-population-preview.component';
//import { OrganizationProfileEncounterGridComponent } from './organization/organization-profile/organization-profile-encounter-grid/organization-profile-encounter-grid.component';
import { OrganizationProfileEncounterPreviewComponent } from './organization/organization-profile/organization-profile-encounter-preview/organization-profile-encounter-preview.component';
import {MatMenuModule} from "@angular/material/menu";
import { InvitationRedeemComponent } from './invitation/invitation-redeem/invitation-redeem.component';
import { ApiKeyAdministrationComponent } from './administration/platform-administration/api-key-administration/api-key-administration.component';
import { NewKeyComponent } from './administration/platform-administration/api-key-administration/new-key/new-key.component';
import { PopulationSettingsPageComponent } from './administration/populations/population-administration/single-population-administration/population-settings-page/population-settings-page.component';
import { PopulationMembersPageComponent } from './administration/populations/population-administration/single-population-administration/population-members-page/population-members-page.component';
import { PopulationContributorsPageComponent } from './administration/populations/population-administration/single-population-administration/population-contributors-page/population-contributors-page.component';
import { PopulationBehaviorComponent } from './administration/populations/population-behavior/population-behavior.component';
import { OrganizationProfileListComponent } from './organization/organization-profile-list/organization-profile-list.component';
import { OrganizationPreviewComponent } from './organization/organization-profile-list/organization-preview/organization-preview.component';
import { PopulationIndividualsComponent } from './administration/population-individuals/population-individuals.component';
import { OrganizationJoinRequestComponent } from './organization/organization-profile/organization-join-request/organization-join-request.component';
import { OrganizationJoinRequestResponseComponent } from './administration/organizations/organization-join-request-response/organization-join-request-response.component';
import { PopulationMembershipRequestsComponent } from './administration/population/population-membership-requests/population-membership-requests.component';
import { UpdateOrganizationDetailsComponent } from './administration/organizations/organization-administration/single-organization-administration/update-organization-details/update-organization-details.component';
import { PermitManagementComponent } from './administration/organizations/organization-administration/single-organization-administration/permit-management/permit-management.component';



@NgModule({
    declarations: [
        LoginComponent,
        UserHomeComponent,
        ManagementComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        AdministrationComponent,
        PopulationSettingsComponentComponent,
        PopulationMembersComponentComponent,
        OrganizationMembersComponentComponent,
        OrganizationSettingsComponentComponent,
        PopulationPreyComponent,
        PopulationContributorsComponent,
        PopulationContributorEditComponent,
        PopulationDescriptionComponent,
        MembershipInvitationComponent,
        PopulationAdministrationComponent,
        OrganizationAdministrationComponent,
        SinglePopulationAdministrationComponent,
        SingleOrganizationAdministrationComponent,
        OrganizationCreationDialogComponent,
        OrganizationUserAddDialogComponent,
        UserProfileImageComponent,
        UserProfileImageUpdateDialogComponent,
        PlatformAdministrationComponent,
        PopulationCreationManagementComponent,
        PopulationCreationApplicationComponent,
        PopulationBannerComponent,
        PopulationBannerUpdateDialogComponent,
        OrganizationProfileComponent,
        OrganizationProfilePictureComponent,
        OrganizationProfilePictureUpdateDialogComponent,
        OrganizationProfileMemberPreviewComponent,
        OrganizationProfilePopulationPreviewComponent,
        OrganizationProfileEncounterPreviewComponent,
        InvitationRedeemComponent,
        ApiKeyAdministrationComponent,
        NewKeyComponent,
        PopulationSettingsPageComponent,
        PopulationMembersPageComponent,
        PopulationContributorsPageComponent,
        PopulationBehaviorComponent,
        OrganizationProfileListComponent,
        OrganizationPreviewComponent,
        PopulationIndividualsComponent,
        OrganizationJoinRequestComponent,
        OrganizationJoinRequestResponseComponent,
        PopulationMembershipRequestsComponent,
        UpdateOrganizationDetailsComponent,
        PermitManagementComponent
    ],
    imports: [
        CommonModule,
        GlobalModule,
        NgxJdenticonModule,
        MatBadgeModule,
        MatExpansionModule,
        RouterModule,
        FinPrintModule,
        CKEditorModule,
        MatMenuModule
    ],
    exports: [
        PopulationPreyComponent,
        UserProfileImageComponent
    ],
    providers: [
        AuthenticationService,
        PasswordConfirmationValidatorService,
        UserContentService,
        UserManagementService
    ]

})
export class UserModule { }
