<div class="settings-container">
  <div class="settings-block" *ngIf="population !== undefined">
    <mat-card class="input-content">
      <mat-card-header>
        <mat-card-title>Main Details</mat-card-title>
        <mat-card-subtitle>Identifiying information</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card-content">
        <mat-form-field class="full-width">
          <mat-label>Species Designation</mat-label>
          <input matInput type="text" [(ngModel)]="population.speciesName"/>
        </mat-form-field>
        <mat-form-field  class="full-width">
          <mat-label>Population Name</mat-label>
          <input matInput type="text" [(ngModel)]="population.displayName"/>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Population Abbreviation</mat-label>
          <input matInput type="text" [(ngModel)]="population.abbreviation"/>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="updateMainSettings(population)">Update Details</button>
      </mat-card-actions>

    </mat-card>


  </div>
  <div class="settings-block" *ngIf="settings; else loadingSettings">
    <mat-card class="input-content">
      <mat-card-header>
        <mat-card-title>
          Submission Settings
        </mat-card-title>
        <mat-card-subtitle>How to deal with data</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="card-content">
        <mat-form-field class="full-width">
          <mat-label>Maximum Image Width</mat-label>
          <input matInput type="number" min="0" [(ngModel)]="settings.submissionImageMaxWidth">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Maximum Image Height</mat-label>
          <input matInput type="number" min="0" [(ngModel)]="settings.submissionImageMaxHeight">
        </mat-form-field>
        <mat-checkbox matTooltip="All images with a single animal detection and a single animal identifier in the 'caption' tag which is known to the database will be automatically assigned to that animal." [(ngModel)]="settings.submissionAutoTag">
          Auto Tag All Single Images
        </mat-checkbox>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="updateSettings(settings)">Update Population Settings</button>
      </mat-card-actions>

    </mat-card>

    </div>
    <div class="settings-block">
    <mat-card class="card input-content">
      <mat-card-header>
        <mat-card-title>Prey Targets</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="prey && fetched">
        <div class="prey" *ngFor="let p of prey" (click)="activateAdding(p.id!)">
          <h4 *ngIf="!editing!.get(p.id!)!">{{p.displayName}}</h4>
          <div class="editing-box" *ngIf="editing!.get(p.id!)">
            <mat-form-field class="full-width" color="accent">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="p!.displayName" />
            </mat-form-field>
            <button mat-button (click)="updateTarget(p)">Submit</button>
            <button mat-button color="warn" (click)="editing!.set(p.id!, false)">Cancel</button>
            <button mat-button color="warn" (click)="deleteTarget(p)">Delete</button>
          </div>
          <mat-divider></mat-divider>
        </div>

        <div class="adding-container" *ngIf="adding">
          <mat-form-field class="full-width" color="accent">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="newPrey!.displayName"/>
          </mat-form-field>
          <button mat-button (click)="createTarget()">Submit</button>
          <button mat-button color="warn" (click)="cancelAdding()">Cancel</button>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="addPreyTarget()" *ngIf="!adding">Add Prey Target</button>
      </mat-card-actions>
      <mat-card-footer *ngIf="!fetched">
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
    </div>
    <div class="settings-block">
      <mat-card class="card input-content">
        <mat-card-header>
          <mat-card-title>Behaviors</mat-card-title>
          <mat-card-subtitle>What are they known to do</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <app-population-behavior [population]="population"></app-population-behavior>
        </mat-card-content>
      </mat-card>
    </div>
  <div class="settings-block" *ngIf="settings">
    <mat-card class="card input-content">
      <mat-card-header>
        <mat-card-title>Membership Settings</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-checkbox [(ngModel)]="settings!.requireJoinRequest" (click)="updateSettings(settings)"
                      matTooltip="Users must submit a request to join the population in any capacity. This will require approval of a population administrator.">
          Require Approval to Join
        </mat-checkbox>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
  </div>


<ng-template #loadingSettings>
  <h4>Getting settings...</h4>
</ng-template>


