import {Component, Inject, OnInit} from '@angular/core';
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {PopulationRoleSet, PopulationService} from "../../../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {PredationTarget} from "../../../../../../../models/dto/animal/predationTarget";
import {UserProfileDto} from "../../../../../../../models/dto/user/userProfileDto";
import {UpdateLocationComponent} from "../update-location-component/update-location.component";
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { WorkspaceService } from '../../../../../../../services/workspace/workspace.service';
import {AnimalBehaviorDto} from "../../../../../../../models/dto/behavior/animalBehaviorDto";
import {OrganizationDto} from "../../../../../../../models/dto/user/organization/organizationDto";
import {DataLimitationLicenseDto} from "../../../../../../../models/dto/encounter/DataLimitationLicenseDto";


export interface UpdateEncounterDetailsDialogData {
  encounter?: EncounterDto;
  user?: UserProfileDto
}

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss']
})
export class UpdateDetailsComponent implements OnInit {
  public userRights: PopulationRoleSet | undefined;
  public predatedSpecies: Array<PredationTarget> = new Array<PredationTarget>();
  public population: PopulationDto | undefined;
  constructor(public dialogRef: MatDialogRef<UpdateDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UpdateEncounterDetailsDialogData,
              private dialog: MatDialog,
              private populationService: PopulationService,
              private workspaceService: WorkspaceService,
              private log: ILoggingService
              ) { }

  ngOnInit(): void {
    if (this.data.encounter === undefined || this.data.encounter.populationId === undefined) {
      this.log.error("Encounter data could not be loaded");
      this.dialogRef.close();
    }
    this.populationService
      .getPopulationRights(this.data.encounter?.populationId!)
      .subscribe(res => {
        this.userRights = res;
      });
    this.populationService.population.subscribe(res => this.population = res);

  }

  onNoClick() {
    this.dialogRef.close()
  }

  removePredatedSpecies(predationTarget: PredationTarget) {
    const idx = this.predatedSpecies.indexOf(predationTarget);
    this.predatedSpecies.splice(idx, 1);
  }

  updateLocation() {
    const ref = this.dialog.open(UpdateLocationComponent, {
      data: {
        encounter: this.data.encounter
      },
      minWidth: "680px"
    });
    ref.afterClosed()
      .subscribe(res => {
      })
  }

  updatePredationEvent($event: { predationEvent: boolean; predationTargets: Array<PredationTarget> }) {
    this.data.encounter!.predationEvent = $event.predationEvent;
    this.data.encounter!.predationTargets = $event.predationTargets;
  }


  updateBehaviors($event: Array<AnimalBehaviorDto>) {
    this.data.encounter!.behaviors = $event;
  }

  getOrganizationIds(): Array<string> {
    if (!this.data.encounter?.organizations) return new Array<string>();
    return this.data.encounter.organizations.map( (value: OrganizationDto) => value.id);
  }

  setEncounterLicense($event: DataLimitationLicenseDto) {
    this.data.encounter!.license! = $event;

  }
}
