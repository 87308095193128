<div class="content-container">
  <div class="content">
    <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
      <mat-step>

        <ng-template matStepLabel>Select your Table of Interest</ng-template>
        <mat-form-field>
          <mat-label>Table</mat-label>
          <mat-select [(ngModel)]="selectedTable">
            <mat-option *ngFor="let table of tables" [value]="table">
              {{table.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperNext>Next</button>
        </div>

      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Export Selection Criteria</ng-template>
        <div class="time-selection-criteria" *ngIf="selectedTable !== undefined">
          <h3>Export Selection Field</h3>
          <mat-radio-group [(ngModel)]="selectedTable.exportCriteria" (selectionchange)="updateDataExportCriteriaForm()" color="accent">
            <mat-radio-button class="example-radio-button" *ngFor="let selection of selectedTable.exportSelection" [value]="selection">
              {{selection.displayName}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>

      </mat-step>
      <mat-step *ngIf="needreason">
        <ng-template matStepLabel>Explanation</ng-template>
        <div class="explain-criteria" *ngIf="needreason">
          <h3>What do you need this data for?</h3>
          <div class="comment-actions" *ngIf="!editing && explanaition == ''">
            <button mat-stroked-button color="accent" (click)="openEditor()">Add Explanation</button>
          </div>
          <div class="comment-actions" *ngIf="!editing && explanaition != ''">
            <div [innerHTML]="explanaition"></div>
            <button mat-stroked-button color="accent" (click)="openEditor()">Edit Explanation</button>
          </div>
          <div class="comment-edit" *ngIf="editing">
            <ckeditor [editor]="Editor" [data]="EditorInit" (change)="log($event)" [tagName]="'editor'"></ckeditor>
            <div class="button-row">
              <button mat-icon-button color="accent" (click)="addExplanaiton()"><mat-icon>done</mat-icon></button>
              <button mat-icon-button color="accent" (click)="closeEditor()"><mat-icon>cancel</mat-icon></button>
            </div>
          </div>
        </div>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Date Selection Criteria</ng-template>
        <div class="time-selection-criteria" *ngIf="selectedTable !== undefined">
          <h3>Date Selection Field</h3>
          <mat-radio-group [(ngModel)]="selectedTable.timeCriteria" (selectionchange)="updateDateSelectionCriteriaForm()">
            <mat-radio-button class="example-radio-button" *ngFor="let selection of selectedTable.timeSelection" [value]="selection">
              {{selection.displayName}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>

      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Data between....</ng-template>
        <mat-form-field color="accent">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="dateSelectionRange" [rangePicker]="picker" (selectionchange)="dateSet = true">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>
      </mat-step>
      <mat-step *ngIf="higherPrivileges">
        <ng-template matStepLabel>Flag Data</ng-template>
        <p>Mark the data as having been archived. This sets the status of the resulting encounters as "Archived" by you on this date. These encounters can then later be excluded from future exports.</p>
        <mat-checkbox [(ngModel)]="flagAsArchived">
          Mark Encounters As Archived
        </mat-checkbox>
        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Exclude Data</ng-template>
        <div class="exclusions">
          <div class="exclude-criteria">
            <p>Data that you have already exported is automatically flagged as having been exported by you.</p>
            <p>Select this option to exclude those data which you have already exported.</p>
            <mat-checkbox [(ngModel)]="excludeData">
              Exclude Already Exported Data
            </mat-checkbox>
          </div>

          <div class="exclude-criteria" *ngIf="higherPrivileges">
            <mat-divider></mat-divider>
            <p>Select this option to exclude those data which have been marked as "Archived". </p>
            <mat-checkbox [(ngModel)]="excludeArchived">
              Exclude Already Archived Data
            </mat-checkbox>
          </div>
        </div>


        <div class="step-button-container">
          <button mat-stroked-button color="accent" matStepperPrevious>Back</button>
          <button mat-stroked-button color="accent" matStepperNext (click)="verifySelection()">Next</button>
        </div>
      </mat-step>
      <mat-step (click)="verifySelection()">
        <ng-template matStepLabel>Send Request</ng-template>
        <div class="submission-button-container">
          <button mat-raised-button (click)="export()" [disabled]="requestStarted && !requestFinished">
            <span *ngIf="!requestStarted && !requestFinished">Submit</span>
            <mat-progress-spinner [diameter]="20" mode="indeterminate" *ngIf="requestStarted && !requestFinished"></mat-progress-spinner>
            <span *ngIf="requestStarted && requestFinished">Done!</span>
          </button>
        </div>
        <div class="step-button-container">
          <button mat-button matStepperPrevious color="accent">Back</button>
          <button mat-button (click)="stepper.reset(); send = false" color="accent">Reset</button>
          <p *ngIf="send">Request has been sent. You will receive an email as soon as it is ready.</p>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>

