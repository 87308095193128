import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationUserAddData} from "../../../organization-user-add-dialog/organization-user-add-dialog.component";
import {OrganizationDto} from "../../../../../../../models/dto/user/organization/organizationDto";

@Component({
  selector: 'app-update-organization-details',
  templateUrl: './update-organization-details.component.html',
  styleUrls: ['./update-organization-details.component.scss']
})
export class UpdateOrganizationDetailsComponent implements OnInit {

  constructor(    public dialogRef: MatDialogRef<UpdateOrganizationDetailsComponent>,
                  @Inject(MAT_DIALOG_DATA) public data: OrganizationDto) { }

    ngOnInit(): void {

    }

  submit() {
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.dialogRef.close()
  }
}
