import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileItem, IFileItem} from "../../../models/items/files/file-item.model";
import {Observable, timer, from, of, throwError} from "rxjs";
import {catchError, concatMap} from "rxjs/operators";
import {IImageService} from "../../../services/files/images/image.service.interface";
import {ImageScalingService, ResizeResult} from "../../../services/files/images/scale/image-scaling.service";
import {PredictionService} from "../../../services/predictions/prediction.service";
import {LocationService} from "../../../services/location/location.service";
import { Location } from "../../../models/location/location.model";
import {UntypedFormControl, Validators} from "@angular/forms";
import {EncountersService} from "../../../services/encounters/encounters.service";
import {EncounterCreationRequestDto} from "../../../models/dto/encounter/encounterCreationRequestDto";
import {SubmitErrorDialogComponent} from "./dialogs/submit-error-dialog/submit-error-dialog.component";
import {OrganizationDto} from "../../../models/dto/user/organization/organizationDto";
import {UserOrganizationService} from "../../../services/user/organization/user-organization.service";
import {PopulationRoleSet, PopulationService} from "../../../services/population/population.service";
import {HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {EncounterCreationResponseDto} from "../../../models/dto/encounter/encounterCreationResponseDto";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {UserContentService} from "../../../services/user/content/user-content.service";
import {UserProfileDto} from "../../../models/dto/user/userProfileDto";
import {PredationTarget} from "../../../models/dto/animal/predationTarget";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {UserManagementService} from "../../../services/user/management/user-management.service";
import {UserDto} from "../../../models/dto/response/user/management/userDto";
import {PopulationSettingsDto} from "../../../models/dto/population/populationSettingsDto";
import {SubmissionPreyComponent} from "./submission-prey/submission-prey.component";
import {SubmissionLocationComponent} from "./submission-location/submission-location.component";
import {SubmissionImagesComponent} from "./submission-images/submission-images.component";
import {SubmissionImagesPreviewComponent} from "./submission-images-preview/submission-images-preview.component";
import {SubmissionUserComponent} from "./submission-user/submission-user.component";
import {PopulationFeedService} from "../../../services/population/population-feed.service";
import {PopulationFeedEntryDto} from "../../../models/dto/population/populationFeedEntryDto";
import {WorkspaceService} from "../../../services/workspace/workspace.service";
import {ErrorHandlerService} from "../../../services/error/error-handler.service";
import {MatDialog} from "@angular/material/dialog";
import {
  SubmissionLicenseVerificationComponent
} from "./submission-license-verification/submission-license-verification.component";
import {Router} from "@angular/router";
import {AnimalBehaviorDto} from "../../../models/dto/behavior/animalBehaviorDto";
import {Upload} from 'tus-js-client';
import {environment} from "../../../environments/environment";


export interface SubmissionLocationType {
  id: string;
  name: string;
}

export interface FileUpload {
  name: string;
  percentage: number;
  started: boolean;
  done: boolean;

  file: File;
  failed: boolean;
}
@Component({
  selector: 'app-submit-data',
  templateUrl: './submit-data.component.html',
  styleUrls: ['./submit-data.component.scss']
})
export class SubmitDataComponent implements OnInit, AfterContentInit {
  @ViewChild(SubmissionPreyComponent) preyComponent!: SubmissionPreyComponent;
  @ViewChild(SubmissionLocationComponent) locationComponent!: SubmissionLocationComponent;
  @ViewChild(SubmissionImagesComponent) imagesComponent!: SubmissionImagesComponent;
  @ViewChild(SubmissionImagesPreviewComponent) previewComponent!: SubmissionImagesPreviewComponent;
  @ViewChild(SubmissionUserComponent) userComponent!: SubmissionUserComponent;

  public fileItems: Array<FileItem> = new Array<FileItem>();

  public encounterName = new UntypedFormControl("", []);
  public encounterDateTime = new UntypedFormControl("", [Validators.required]);

  public currentStepText = "Creating Encounter..."
  public encounterSaveFinished = false;
  public encounterSaveStarted = false;
  public uploadsFinished = 0;
  public encounterSaveConfirmed = false;
  public selectedOrganizations = new UntypedFormControl([])
  public organizations: Array<OrganizationDto> | undefined;
  public selectedLocation: Location | undefined;
  public percentFinished = 0.0;
  public dataLimitation?: string;
  public completeStatus = 'unknown';
  public uploads: Array<FileUpload> = []

  public completeOptions = [
    'unknown',
    'complete',
    'incomplete'
  ]


  public population: PopulationDto | undefined;
  public user: UserProfileDto | undefined;


  public populationRights: PopulationRoleSet | undefined;
  public usersObs: Observable<Array<UserDto>> | undefined;
  public submissionUser: UserProfileDto | undefined;
  public populationSettings: PopulationSettingsDto | undefined;
  complete = false;


  private fileCounter = 0;
  public maxUploads = 4;


  public steps = {
    encounterSaved: false,
    uploadsFinished: false,
    metaDataSaved: false,
    pipelineStarted: false,
  }

  public predationEvent: boolean = false;
  public predationTargets: Array<PredationTarget> = [];
  files: Array<any> = [];
  encounterNotes: string | undefined;
  public encounterId: string | undefined;
  selectedBehaviors?: Array<AnimalBehaviorDto>;
  private request?: EncounterCreationRequestDto;

  public disableLocation: boolean = false;

  constructor(
    public imageService: IImageService,
    public scalingService: ImageScalingService,
    public predictionService: PredictionService,
    public locationService: LocationService,
    public encounterService: EncountersService,
    public organizationService: UserOrganizationService,
    public dialog: MatDialog,
    public populationService: PopulationService,
    public log: ILoggingService,
    public userService: UserContentService,
    private authService: AuthenticationService,
    private userManagementService: UserManagementService,
    private feedService: PopulationFeedService,
    private workspaceService: WorkspaceService,
    private errorHandler: ErrorHandlerService,
    private router: Router
    ) { }

  ngAfterContentInit() {
  }

  ngOnInit(): void {

    this.workspaceService.workspace
      .subscribe(res => {
        if (res && res.settings) {
          this.population = res.settings!.population;
          this.populationRights = res.settings!.populationRoles;
          this.populationSettings = res.settings!.populationSettings;
          this.user = res.settings!.user;
          this.submissionUser = res.settings!.user;
        }
      })

    this.usersObs = this.userManagementService.getUserDtos();
    this.organizationService.getUserOrganizations()
      .subscribe({
        next: (value: Array<OrganizationDto>) => {
          this.organizations = value;
        }, error: (err: HttpErrorResponse) => {
          this.errorHandler.handleRequestError("Fetching Organizations", err);
        }
      })

  }


  formValid(): boolean {
    const fieldsValid = this.encounterDateTime.valid && this.encounterName.valid && this.selectedLocation !== undefined;
    const locationInformationValid: boolean = this.selectedLocation !== undefined && this.selectedLocation.name !== undefined && this.selectedLocation.name.length > 0 && this.selectedLocation.latitude !== undefined && this.selectedLocation.longitude !== undefined;
    const contentValid = this.fileItems.length !== undefined && this.fileItems.length > 0;
    //const contentValid = this.files !== undefined && this.files.length > 0;
    return fieldsValid && locationInformationValid && contentValid;
  }




  confirmEncounterSave() {
    this.encounterSaveConfirmed = true;
    this.uploadsFinished = 0;
    this.reset();
    if (this.encounterId) {

      this.log.info("Redirecting to encounter.")
      this.router.navigate([`/encounters/${this.encounterId}`]);
      this.feedService.createFeedEntry(this.population?.id!, this.encounterId).subscribe(res => {})
    }

  }

  async _handleEncounterSuccess(response: any, encounter: EncounterCreationRequestDto) {
    this.fileCounter = 0;

    for (let file of this.files) {
      this.uploads.push({
        name: file.name,
        percentage: 0,
        done: false,
        file: file,
        failed: false,
        started: false
      })
    }

    const result = this.uploads.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / this.maxUploads)

      if(!resultArray[chunkIndex]) {
        // @ts-ignore
        resultArray[chunkIndex] = [] // start a new chunk
      }

      // @ts-ignore
      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])


    for (let uploadList of result) {
      // @ts-ignore
      for (let element of uploadList) {
        this.uploadFile(element.file)
      }
      // @ts-ignore
      while (uploadList.findIndex(a => !a.done) !== -1) {
        await this.sleep(2000)
      }
    }

  }

  sleep(timeMs: number): Promise<any> {
    return timer(timeMs).toPromise().then(res => {});
  }


  uploadFile(file: File) {
    const endpoint = `${environment.server.baseUrl}${environment.server.api.file.delete}/chunk`
    const item = this.uploads.find(a => a.name == file.name);
    item!.done = false;
    item!.percentage = 0;
    item!.failed = false;
    const upload = new Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 10 * 60 * 1000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {
        encounterId: this.encounterId!,
      },
      onError: (error: any) => {
        const item = this.uploads.find(a => a.name == file.name);
        item!.failed! = true;
      },
      onProgress: (bytesUploaded: any, bytesTotal: any) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100);
        const item = this.uploads.find(a => a.name == file.name);
        item!.percentage! = percentage;
        item!.started! = true;
      },
      onSuccess: () => {
        const item = this.uploads.find(a => a.name == file.name);
        item!.percentage! = 100;
        item!.done! = true;
        this.fileCounter++;
        this.uploadsFinished++;
//        upload.
      },
    })

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then( (previousUploads: any)  => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0])
      }

      // Start the upload
      upload.start()
    })
  }



  /**
   * Submit encounter, get back encounter information, attach to file items, push files
   */
  submit() {

    const encounter: EncounterCreationRequestDto = {
      description: this.encounterNotes,
      location: {
        name: this.selectedLocation?.name,
        latitude: this.selectedLocation?.latitude,
        longitude: this.selectedLocation?.longitude
      },
      dateTime: this.encounterDateTime.value.toDate(),
      organizationIds: this.selectedOrganizations.value.length > 0 ? this.selectedOrganizations.value : [],
      populationId: this.population?.id!,
      predationEvent: this.predationEvent,
      predationTargets: this.predationTargets,
      submissionUser: this.submissionUser,
      informNewUser: this.submissionUser?.informUser ?? false,
      complete: this.complete,
      dataLimitation: this.dataLimitation,
      completeStatus: this.completeStatus,
      encounterBehaviors: this.selectedBehaviors

    }
    const ref = this.dialog.open(SubmissionLicenseVerificationComponent, {
      data: {
        encounter: encounter,
        organizationIds: encounter.organizationIds
      }
    })
    this.request = encounter;
    ref.afterClosed().subscribe(res => {
      console.log(encounter);
      if (res) {
        encounter.name = this.encounterService.getEncounterName({location: encounter.location, dateTime: encounter.dateTime, user: encounter.submissionUser})
        this.encounterSaveStarted = true;
        this.encounterService.createEncounter(encounter)
          .subscribe( (response: EncounterCreationResponseDto) => {
            this.steps.encounterSaved = true;
            this.currentStepText = "Starting file uploads..."
            if (response.successful) {
              encounter.id = response.id;
              this.encounterId = encounter.id;
              this._handleEncounterSuccess(response, encounter);
            }
            this.encounterSaveFinished = true;
          })
      } else {
        this.log.info("You must select a license before you submit your data.");
        return;
      }
    })

  }


  updateLocation($event: Location) {
    this.selectedLocation = $event;
  }

  reset() {
    this.fileItems = [];
    this.submissionUser = this.user;
    this.selectedOrganizations.reset([]);
    this.encounterSaveStarted = false;
    this.encounterSaveConfirmed = false;
    this.encounterSaveFinished = false;
    this.preyComponent.reset();
    this.imagesComponent.reset();
    this.previewComponent.reset();
    this.locationComponent.reset();
    if (this.userComponent) {
      this.userComponent.reset();
    }

    this.encounterDateTime.reset(undefined);
    this.selectedOrganizations.reset([]);
    this.percentFinished = 0.0;
  }


  updatePredationEvent($event: { predationEvent: boolean; predationTargets: Array<PredationTarget> }) {
    this.predationEvent = $event.predationEvent;
    this.predationTargets = $event.predationTargets;
  }

  dialogEvent($event: boolean) {
    this.disableLocation = $event;
  }

}
