import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import { ImageService } from "../../../../../services/utilities/image.service";
import { EncountersService } from "../../../../../services/encounters/encounters.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ImageSelectDialogComponent} from "./image-select-dialog/image-select-dialog.component";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {AnimalImageDto} from "../../../../../models/dto/animal/animalImageDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {ExtractedImage} from "../../../../../models/dto/inference/verificationTaskDto";
import {AnimalProfileImageDto} from "../../../../../models/dto/animal/animalProfileImageDto";
import {PopulationService} from "../../../../../services/population/population.service";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {MatDialog} from "@angular/material/dialog";
import {DateService} from "../../../../../services/utilities/date.service";
import {Router} from "@angular/router";
import {IndividualSearchGalleryComponent} from "../../individual-search-gallery/individual-search-gallery.component";
import {AnimalDto} from "../../../../../models/dto/animal/animalDto";
import { EncounterDto } from '../../../../../models/dto/encounter/encounterDto';

@Component({
  selector: 'app-profile-image-management',
  templateUrl: './profile-image-management.component.html',
  styleUrls: ['./profile-image-management.component.scss']
})
export class ProfileImageManagementComponent implements OnInit, OnChanges {
  @Input() profile: AnimalProfileDto | undefined;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  private population: PopulationDto | undefined;
  public canUserChange: boolean | undefined;
  public animalImageDto: AnimalImageDto | undefined;
  public profileImage: ExtractedImage | undefined;
  public positionImages: Array<AnimalProfileImageDto> | undefined;
  public profileImages: Map<string, AnimalProfileImageDto> = new Map<string, AnimalProfileImageDto>();
  public profileImageLoading: boolean = false;
  profileImageLoadingStarted: boolean = false;
  profileImageLoadingFinished: boolean = false;
  public encounterDatesRight: Date | undefined;
  public encounterDatesLeft: Date | undefined;

  constructor(
    public imageService: ImageService,
    public encounterService: EncountersService,
    private dialog: MatDialog,
    private animalService: AnimalService,
    private logService: ILoggingService,
    private workspaceService: WorkspaceService,
    private dateService: DateService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (this.profile === undefined || this.profile.profileImages === undefined) {return;}

    this.workspaceService.workspace.subscribe(res => {
      this.population = res.settings?.population;
      this.canUserChange = (res.settings?.populationRoles?.administrator || res.settings?.populationRoles?.professional) ?? false;
    })

    this.profileImages = new Map<string, AnimalProfileImageDto>();
    for (let i of this.profile.profileImages) {
      i.extractedImageObs = this.animalService.getExtractedImageById(i.extractedImageId, "full");
      this.profileImages.set(i.side!, i);
      //const image = this.profileImages.get(i.side!);
      this.encounterService.getEncounter(i!.encounterId!).subscribe({
        next: (value: EncounterDto) => {
          if (i.side! == "right") {
            this.encounterDatesRight = value.dateTime;
          }
          else {
            this.encounterDatesLeft = value.dateTime;
          }
        },
        error: (value: HttpErrorResponse) => {

        }
      });
    }
  }

  updateImages(side: string) {
    const dialogRef = this.dialog.open(ImageSelectDialogComponent,  {
      data: {
        profile: this.profile,
        side: side
      },
    });

    dialogRef.afterClosed().subscribe(async (result: AnimalImageDto | undefined) => {
      if (result !== undefined) {
        this.animalService
          .setSidedImage(this.profile?.id!, result.side!, result.id!)
          .subscribe({
            next: (value: any) => {
              this.profileImages.set(result.side!, result);
              this.logService.info(`Updated ${result.side} side image for ${this.profile?.identifier}`)
            },
            error: (value: HttpErrorResponse) => {
              this.logService.info(`Could not update ${result.side} side image for ${this.profile?.identifier} : ${value.message}`)
            }
          })

      }

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile.previousValue !== undefined && changes.profile.previousValue.id !== changes.profile.currentValue.id) {
      this.ngOnInit();
    }

  }

  getTooltipContent(side: string) {
    const image = this.profileImages.get(side);
    //const image = this.profile?.profileImages?.find((obj) => obj.side == side)
    if (!image) return "";
    if (side == "Right") {
      return `Date: ${this.dateService.formatDateFromAny(this.encounterDatesRight, false)}
      Location: ${image.location}
      Photographer: ${image.photographer}`;
    }
    return `Date: ${this.dateService.formatDateFromAny(this.encounterDatesLeft, false)}
      Location: ${image.location}
      Photographer: ${image.photographer}`;
  }

  visitEncounter(side: string): void {
    const image = this.profileImages.get(side);
    if (!image) return;
    this.router.navigate([`/encounters/${image.encounterId}`], {queryParams: {open: image.fileItemId}});
  }

  viewImage(side: string) {
    //const image = this.profileImages.get(side);
    //if (!image) return;
    this.dialog.open(ImageSelectDialogComponent, {
      data: {
        profile: this.profile,
        side: side
      }
    }
    )
    /*this.dialog.open(IndividualSearchGalleryComponent, {
      data: {
        results: [],
        selected: image,
        imageId: image.extractedImageId
      },
    })*/
  }
}
