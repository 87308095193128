import {Component, Inject, OnInit} from '@angular/core';
import {EncountersService} from "../../../../../../../services/encounters/encounters.service";
import {Router} from "@angular/router";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../../../services/error/error-handler.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EncounterMergeDialogData} from "../encounter-merge-dialog/encounter-merge-dialog.component";
import {EncounterDto} from "../../../../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../../../../services/utilities/date.service";

export interface  ArchiveStatusUpdateData {
  editing?: boolean;
  encounter: EncounterDto;
}
@Component({
  selector: 'app-archival-status-dialog',
  templateUrl: './archival-status-dialog.component.html',
  styleUrls: ['./archival-status-dialog.component.scss']
})
export class ArchivalStatusDialogComponent implements OnInit {

  public archived: boolean = false;
  constructor(
    public encounterService: EncountersService,
    private router: Router,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService,
    public dateService: DateService,
    public dialogRef: MatDialogRef<ArchivalStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArchiveStatusUpdateData,
  ) { }

  ngOnInit(): void {
        this.archived = this.data.encounter.archived ?? false;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(this.data.encounter);
  }
}
