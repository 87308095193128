<div class="content-container">
  <h2>Behavior</h2>
  <div class="behavior-selection" *ngIf="behaviors">
    <mat-form-field class="full-width" color="accent">
      <mat-label>Observed Behaviors</mat-label>
      <mat-select multiple [(ngModel)]="selectedBehaviors" (selectionChange)="updateBehaviors()">
        <mat-option *ngFor="let behavior of behaviors" [value]="behavior">
          {{ behavior.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="behavior-selection" *ngIf="!behaviors">
    No behaviors defined for the population.
  </div>
</div>
