import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganizationDto} from "../../../../models/dto/user/organization/organizationDto";
import {Observable} from "rxjs";
import {UntypedFormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {
  OrganizationCreationDialogComponent
} from "../../../user/administration/organizations/organization-creation-dialog/organization-creation-dialog.component";
import {UserManagementService} from "../../../../services/user/management/user-management.service";
import {UserOrganizationService} from "../../../../services/user/organization/user-organization.service";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {OrganizationCreationDto} from "../../../../models/dto/organization/organizationCreationDto";
import {CreateOrganizationResponseDto} from "../../../../models/dto/user/organization/createOrganizationDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {A} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-submission-organization',
  templateUrl: './submission-organization.component.html',
  styleUrls: ['./submission-organization.component.scss']
})
export class SubmissionOrganizationComponent implements OnInit, AfterContentInit {
  @Input() organizations: Array<OrganizationDto> | undefined;
  @Input() existingOrganizations?: Array<OrganizationDto>;
  @Input() selectedOrganizations!: UntypedFormControl;
  @Output() organizationsUpdated: EventEmitter<Array<string>> = new EventEmitter<Array<string>>()
  constructor(private dialog: MatDialog,
              private userService: UserManagementService,
              private orgService: UserOrganizationService,
              private log: ILoggingService) { }

  ngAfterContentInit(): void {

    }

  ngOnInit(): void {
    if (this.existingOrganizations !== undefined && this.existingOrganizations.length > 0) {
      this.selectedOrganizations.setValue(this.existingOrganizations);
    }

  }

  removePublic(organizations: Array<OrganizationDto>) {
    return organizations.filter(s => s.name.toLowerCase() !== "public");
  }

  announceUpdates() {
    this.organizationsUpdated.emit(this.selectedOrganizations.value);
  }

  createOrganization() {
    const ref = this.dialog.open(OrganizationCreationDialogComponent,
      {data:
              {
                name: "",
                allUsers: this.userService.getUserDtos(),
                users: new Array<UserProfileDto>()},
        width: "500px"})
    ref.afterClosed().subscribe(res => {
      if (res === undefined) {return;}
      const dto: OrganizationCreationDto = {users: res.users, name: res.name, researchOrganization: res.researchOrganization};

      this.orgService.createNewOrganization(dto).subscribe({
        next: (value: CreateOrganizationResponseDto) => {
          this.log.info(`${dto.name} created!`);
          this.orgService.getUserOrganizations().subscribe( res => {
            this.organizations = res;
            this.selectedOrganizations.setValue([value.id]);
            this.announceUpdates();

          })
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create organization: ${value.message}`)
        }
      })
    })
  }

  fetchSetOrganizationPermit(organization: OrganizationDto) {
    if (organization.researchOrganization) {
      console.log(organization);
    }
  }
}
