import {Component, EventEmitter, inject, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataLimitationLicenseDto} from "../../../../models/dto/encounter/DataLimitationLicenseDto";
import {Subscription} from "rxjs";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {LicenseService} from "../../../../services/license/license.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EncounterCreationRequestDto} from "../../../../models/dto/encounter/encounterCreationRequestDto";
import {A} from "@angular/cdk/keycodes";
import {FormBuilder, Validators} from "@angular/forms";

export interface VerificationData {
  encounter: EncounterCreationRequestDto,
  organizationIds: Array<string>
}
@Component({
  selector: 'app-submission-license-verification',
  templateUrl: './submission-license-verification.component.html',
  styleUrls: ['./submission-license-verification.component.scss']
})
export class SubmissionLicenseVerificationComponent implements OnInit, OnDestroy {

  public licenses: Array<DataLimitationLicenseDto> | undefined;
  private subs: Array<Subscription> = new Array<Subscription>();
  public dataLimitation: string | undefined;
  public license: DataLimitationLicenseDto | undefined;
  public licenseLogo: any | undefined;
  public validSelection = true;

  private _formBuilder = inject(FormBuilder);

  firstFormGroup = this._formBuilder.group({
    firstCtrl: [false, Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["Unknown", Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: [{} as DataLimitationLicenseDto, Validators.required],
  });

  public public = true;
  public attribute = false;
  public selectionsMade = false;
  public licenseTooltip = "";
  public limitLevel = 1;
  public errorMessages: Array<string> = new Array<string>();
  nominalSet: boolean = false;
  completeSet: boolean = false;
  licenseSet: boolean = false;
  validSubmission: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<SubmissionLicenseVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VerificationData,
    private encounterService: EncountersService,
    private licenseService: LicenseService,
  ) {

  }


  updateValidSubmission() {
    if (!this.validSelection) {
      this.validSubmission = false;
      return;
    }
    this.validSubmission = this.nominalSet && this.completeSet && this.licenseSet;
  }

  updateNominal(value: boolean) {
    this.nominalSet = value;
    this.updateValidSubmission();
  }

  updateComplete(value: boolean) {
    this.completeSet = value;
    this.updateValidSubmission();
  }
  updateLicenseSet(value: boolean) {
    this.licenseSet = value;
    this.updateValidSubmission();
  }


  onNoClick() {
    this.dialogRef.close();
  }

  updateLicense() {
    this.errorMessages = new Array<string>();
    if (this.public && !this.attribute) {
      this.limitLevel = 1;
      this.validSelection = true;

    } else if (this.public && this.attribute) {
      this.limitLevel = 2;
      this.validSelection = true;
    } else {
      this.limitLevel = 3;
      if (this.data.organizationIds === undefined || this.data.organizationIds.length == 0) {
        this.validSelection = false;
        if (!this.data.encounter.editing) {
          this.errorMessages.push("You have selected a license for organizational use but not selected any organizations. Please either select an organization or a different license.")
        } else {
          this.errorMessages.push("You have selected a license for organizational use but not selected any organizations. Please assign this encounter to an organization or choose a different license.")

        }
      }
    }
    const item = this.licenses!.findIndex(a => a.limitLevel == this.limitLevel);
    this.license = this.licenses![item];
    this.dataLimitation = this.license.id;
    this.licenseLogo = this.getLicenseLogos(this.license!)
    this.thirdFormGroup.setValue({thirdCtrl: this.license})
    this.data.encounter.dataLimitation = this.dataLimitation;

  }

  ngOnInit(): void {
    this.subs.push(this.encounterService
      .getLicenses()
      .subscribe(res => {
          this.licenses = JSON.parse(JSON.stringify(res.sort((a, b) => a.limitLevel! - b.limitLevel!)));
          this.dataLimitation = this.licenses![0].id;
          this.data.encounter.dataLimitation = this.dataLimitation;
          this.license = this.licenses![0];
          this.licenseLogo = this.getLicenseLogos(this.license);
          this.data.encounter.dataLimitationConfirmed = false;
        }

      ))
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  getLicenseLogos(license: DataLimitationLicenseDto) {
    return this.licenseService.getLogosForLicense(license)
  }
}
