<div class="content-container">
  <div class="content-header">
    <h2>Membership Requests</h2>
  </div>
  <div class="content">
    <mat-accordion *ngIf="joinRequests.length > 0">
      <mat-expansion-panel *ngFor="let request of joinRequests" class="member">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{request.user.firstName}} {{request.user.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{request.user.email}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="message" *ngIf="request.requestingMessage && request.requestingMessage!.length > 0">
          <h4>Their message to you:</h4>
          <p>{{request.requestingMessage}}</p>
        </div>
        <div class="member-info">

        </div>
        <div class="member-changes">
          <button mat-raised-button color=accent [routerLink]="getMemberLink(request.user)" class="clickable">View Their Profile Page</button>
          <button mat-raised-button color="accent" (click)="respondToRequest(request)">Respond To Their Request</button>
<!--          <button mat-stroked-button color="accent" *ngIf="!isUserOrganizationAdmin(member)" (click)="promoteUser(member)">Promote To Administrator</button>-->
<!--          <button mat-stroked-button color="warn" (click)="removeUserFromOrganization(member)">Remove {{member.firstName}} {{member.lastName}} from {{organization!.name}}</button>-->
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="joinRequests.length == 0">
      <h4>No requests to join the organization at this time.</h4>
    </div>
  </div>
  <div class="content-header">
    <h2>Current Members</h2>
    <button mat-icon-button color="accent" (click)="addMembers()"><mat-icon>person_add</mat-icon></button>
  </div>
  <div class="content">
    <mat-accordion *ngIf="membersObs | async as members; else loading">
      <mat-expansion-panel *ngFor="let member of members" class="member" (afterExpand)="loadMember(member)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{member.firstName}} {{member.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{member.email}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="member-info">
          <h3 [routerLink]="getMemberLink(member)" class="clickable">Profile Page</h3>
        </div>
        <div class="member-changes">
          <button mat-stroked-button color="accent" *ngIf="isUserOrganizationAdmin(member)" (click)="demoteUser(member)">Demote From Administrator</button>
          <button mat-stroked-button color="accent" *ngIf="!isUserOrganizationAdmin(member)" (click)="promoteUser(member)">Promote To Administrator</button>
          <button mat-stroked-button color="warn" (click)="removeUserFromOrganization(member)">Remove {{member.firstName}} {{member.lastName}} from {{organization!.name}}</button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<ng-template #loading>
<!--  <app-area-loading [criteria]="(organizations | async)?.length == 0" [diameter]="50"></app-area-loading>-->
</ng-template>
