import {
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
} from "@angular/core";
import {PopulationService} from "../../../services/population/population.service";
import {Observable} from "rxjs";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {
  NewPopulationDialogComponent,
} from "./new-population-dialog/new-population-dialog.component";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {WorkspaceService} from "../../../services/workspace/workspace.service";
import {PopulationCreationApplicationDto} from "../../../models/dto/population/populationCreationApplicationDto";
import {PopulationManagementService} from "../../../services/population/population-management.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../services/error/error-handler.service";
import {MatDialog} from "@angular/material/dialog";
import {ItemResponseDto} from "../../../models/dto/response/itemResponseDto";

@Component({
  selector: 'global-population-select',
  templateUrl: './population-select.component.html',
  styleUrls: ['./population-select.component.scss']
})
export class PopulationSelectComponent implements OnInit {
  @Input() auth = false;
  @Output() populationChanged = new EventEmitter<PopulationDto>();


  public populations: Observable<Array<PopulationDto>> | undefined;
  public selectedPopulation: PopulationDto | undefined;

  constructor(
    private populationService: PopulationService,
    private dialog: MatDialog,
    private log: ILoggingService,
    private workspaceService: WorkspaceService,
    private populationManagementService: PopulationManagementService,
    private errorHandler: ErrorHandlerService
  ) { }


  ngOnInit(): void {
    this.populations = this.populationService.getUserPopulations();
    this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings && res.settings.population) {
        this.selectedPopulation = res.settings!.population;
      } else {
        this.selectedPopulation = undefined;
      }
    })
  }

  updatePopulation(population: PopulationDto): void {
    this.selectedPopulation = population;
    this.populationChanged.emit(population);
    this.populationService.select(population);
    // this.populationService.getPopulationJoinRequestRequired(population.id!).subscribe({
    //   next: (value: ItemResponseDto<boolean>) => {
    //     console.log(value);
    //     if (!value.successful) {
    //       this.log.error(`Could not check if population requires join permission. ${value.errorMessages.join(', ')}`);
    //       return;
    //     }
    //     if (value.item) {
    //       // DO FILLOUT REQUEST
    //     } else {
    //       // DO STANDARD THING
    //       // this.selectedPopulation = population;
    //       // this.populationChanged.emit(population);
    //       // this.populationService.select(population);
    //     }
    //   }
    // })

  }

  newPopulation(): void {
    const ref = this.dialog.open(NewPopulationDialogComponent, {
      closeOnNavigation: true,
      data: new PopulationCreationApplicationDto()
    });
    ref.afterClosed().subscribe( res => {
      if (res && res.location) {
        res.location.name = `${res.displayName} center`;
        this.populationManagementService.createApplication(res).subscribe({
          next: () => {
            this.log.info(`Application for ${res.displayName} successfully created`);
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleRequestError("Creating Population Request", err);
          }
        })
      }

    })
  }

}
