import {Component, Input, OnInit} from '@angular/core';
import {OrganizationDto} from "../../../../../../../models/dto/user/organization/organizationDto";
import {WorkspaceService} from "../../../../../../../services/workspace/workspace.service";
import {UserOrganizationService} from "../../../../../../../services/user/organization/user-organization.service";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {OrganizationPermit} from "../../../../../../../models/dto/organization/organizationPermit";
import {ItemResponseDto} from "../../../../../../../models/dto/response/itemResponseDto";
import {UuidService} from "../../../../../../../services/utilities/uuid.service";
import {ResponseDto} from "../../../../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {DateService} from "../../../../../../../services/utilities/date.service";

@Component({
  selector: 'app-permit-management',
  templateUrl: './permit-management.component.html',
  styleUrls: ['./permit-management.component.scss']
})
export class PermitManagementComponent implements OnInit {

  @Input() organization?: OrganizationDto

  public permits? : Array<OrganizationPermit>;
  public saving = false;

  constructor(
    private organizationService: UserOrganizationService,
    private log: ILoggingService,
    private idService: UuidService,
    public dateService: DateService
              ) {


  }

  ngOnInit() {
    if (this.organization && this.organization.researchOrganization) {
      this.organizationService.getOrganizationPermits(this.organization.id).subscribe({
        next: (value: ItemResponseDto<Array<OrganizationPermit>>) => {
          if (value.successful) {
            this.permits = value.item?.sort((a, b) => {
              // Sort by "isActive" first
              if (a.isActive && !b.isActive) return -1;
              if (!a.isActive && b.isActive) return 1;

              // If "isActive" is the same, sort by "validFrom" date
              if (a.validFrom === undefined && b.validFrom === undefined) return 0; // Both are null, keep original order
              if (a.validFrom === undefined) return 1; // Null "validFrom" goes last
              if (b.validFrom === undefined) return -1;

              // @ts-ignore
              return a.validFrom - b.validFrom;
            });
          }
        }
      })
    }
  }


  startNewPermitAdd() {
    const permit: OrganizationPermit = {
      permit: "",
      editing: true,
      isActive: false,
      id: this.idService.generate(),
      organizationId: this.organization!.id,
      newPermit: true

    }
    if (!this.permits) {
      this.permits = new Array<OrganizationPermit>();
    }

    this.permits.unshift(permit);
  }

  startPermitEdit(permit: OrganizationPermit) {
    if (permit.editing) return;
    for (let p of this.permits!) {
      p.editing = false;
    }
    for (let p of this.permits!) {
      if (!p.permit) {
        this.permits!.splice(this.permits!.indexOf(p), 1)
      }
    }

    permit.editing = true;

  }

  save(permit: OrganizationPermit) {
    this.saving = true;
    permit.editing = false;

    if (permit.newPermit) {

      this.organizationService.addOrganizationPermit(permit).subscribe({
        next: (response: ItemResponseDto<OrganizationPermit>) => {
          if (response.successful) {
            this.log.info("Permit added.");
            permit.newPermit = false;
            if (permit.isActive) {
              this.updateAndSetInactive(permit);
            }
          }
          this.saving = false;

        }, error: (value: HttpErrorResponse) => {
          this.log.error(`Could not add permit: ${value.message}`);
          this.saving = false;
        }
      })

    } else {
      this.organizationService.updateOrganizationPermit(permit).subscribe({
        next: (response: ItemResponseDto<OrganizationPermit>) => {
          if (response.successful) {
            this.log.info("Permit updated.");
            if (permit.isActive) {
              this.updateAndSetInactive(permit);
            }
          }
          this.saving = false;
        }, error: (value: HttpErrorResponse) => {
          this.log.error(`Could not add permit: ${value.message}`);
          this.saving = false;
        }
      })
    }




  }

  private updateAndSetInactive(permit: OrganizationPermit) {
    for (let p of this.permits!) {
      if (p.isActive && p.id !== permit.id) {
        p.isActive = false;
        this.saving = true;
        this.organizationService.updateOrganizationPermit(p).subscribe({
          next: (response: ItemResponseDto<OrganizationPermit>) => {
            if (response.successful) {
              this.log.info("Permit updated.");
            }
            this.saving = false;
          }, error: (value: HttpErrorResponse) => {
            this.log.error(`Could not add permit: ${value.message}`);
            this.saving = false;
          }
        })
      }
    }
  }


  cancel(permit: OrganizationPermit) {
    permit.editing = false;
    if (permit.newPermit) {
      this.permits!.splice(this.permits!.indexOf(permit), 1)
    }
  }

  delete(permit: OrganizationPermit) {
    this.saving = true;
    this.organizationService.deleteOrganizationPermit(permit.id).subscribe({
      next: (value: ResponseDto) => {
        if (value.successful) {
          this.saving = false;
          this.log.info("Successfully deleted permit")
          this.permits!.splice(this.permits!.indexOf(permit), 1)
        }
      }, error: (value: HttpErrorResponse) => {
        this.log.error(`Could not add permit: ${value.message}`);
        this.saving = false;
      }
    })
  }
}
