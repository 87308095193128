import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataLimitationLicenseDto} from "../../../../models/dto/encounter/DataLimitationLicenseDto";
import {EncountersService} from "../../../../services/encounters/encounters.service";
import {Subscription} from "rxjs";
import {LicenseService} from "../../../../services/license/license.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-submission-limitation',
  templateUrl: './submission-limitation.component.html',
  styleUrls: ['./submission-limitation.component.scss']
})
export class SubmissionLimitationComponent implements OnInit, OnDestroy {
  @Input() selectedOrganizations: Array<string> | undefined;
  @Input() selectedLicense?: DataLimitationLicenseDto;
  @Output() updated: EventEmitter<DataLimitationLicenseDto> = new EventEmitter<DataLimitationLicenseDto>();
  public licenses: Array<DataLimitationLicenseDto> | undefined;
  private subs: Array<Subscription> = new Array<Subscription>()
  public dataLimitation: DataLimitationLicenseDto | undefined;
  constructor(
    private encounterService: EncountersService,
    private licenseService: LicenseService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.log(this.selectedLicense);
    this.subs.push(this.encounterService
      .getLicenses()
      .subscribe(res => {
        this.licenses = res.sort((a, b) => a.limitLevel! - b.limitLevel!);
        console.log(this.licenses)
        if (this.selectedLicense) {
          this.dataLimitation = this.selectedLicense;
        } else {
          this.dataLimitation = this.licenses[0];
        }

        this.updated.emit(this.dataLimitation);
        }

      ))
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  update() {
    console.log(this.dataLimitation)
    this.updated.emit(this.dataLimitation);
  }

  isDisabled(license: DataLimitationLicenseDto) {
    return license.limitLevel === 3 && (this.selectedOrganizations === undefined || this.selectedOrganizations.length === 0);
  }

  getLicenseLogos(license: DataLimitationLicenseDto) {
    return this.licenseService.getLogosForLicense(license)
  }
}
