<!--<div class="encounter-preview-container" *ngIf="encounter && encounter.preview && encounter.preview.thumbnail" [routerLink]="getRouterLink(encounter)">-->
<div class="encounter-preview-container" *ngIf="encounter && encounter.preview && encounter.preview.thumbnail" (click)="route()">
  <app-gallery-preview-loading-image [height]="266" [width]="400"></app-gallery-preview-loading-image>
  <img [src]="maybePrefix(encounter.preview.thumbnail)" />
  <div class="encounter-overlay shade">
    <div class="encounter-details">
      <div class="encounter-title">
        <h3><span>{{formatTime(encounter.dateTime)}}</span><span *ngIf="encounter.location">{{encounter.location.name}}</span> <span *ngIf="encounter.user">{{encounter.user.firstName}} {{encounter.user.lastName}}</span></h3>
      </div>
      <div class="encounter-description" *ngIf="encounter.preview">
        <h4>{{encounter.preview.imageCount}} Images</h4>
        <h4>{{encounter.preview.detectionCount}} Detections</h4>
        <!--      <h4>{{encounter.preview.confirmedAnimalCount}} Animals</h4>-->
        <h4 *ngIf="formatAnimals()">{{formatAnimals()}}</h4>
      </div>
    </div>
    <div class="indicators">
      <div class="indicator encounter-status-indicator" [matTooltip]="getStatusTooltip(encounter)" matTooltipPosition="after">
        <div class="status-indicator" [className]="getStatusClass(encounter)"></div>
      </div>
      <div class="indicator encounter-complete-indicator" [matTooltip]="getCompleteTooltip(encounter)" matTooltipPosition="after">

        <mat-icon *ngIf="encounter.completeStatus! === 'unknown'">help</mat-icon>
        <mat-icon *ngIf="encounter.completeStatus! === 'complete'">circle</mat-icon>
        <mat-icon *ngIf="encounter.completeStatus! === 'incomplete'">incomplete_circle</mat-icon>
      </div>
      <div class="indicator encounter-predation-indicator" *ngIf="encounter.predationEvent" matTooltip="Predation Event" matTooltipPosition="after">
        <mat-icon>restaurant</mat-icon>
      </div>
      <div class="indicator encounter-archived-indicator" *ngIf="encounter.archived && higherPrivileges" [matTooltip]="getArchivedTooltip(encounter)" matTooltipPosition="after">
        <mat-icon>beenhere</mat-icon>
      </div>
      <div class="license" *ngIf="getLicenseLogos() as logos">
        <div class="license-logos">
        <span class="license-logo" *ngFor="let logo of logos"  >
          <a [href]="logo.link" target="_blank"  >
            <img [src]="logo.img" [alt]="logo.text"/>
          </a>
        </span>
        </div>
      </div>
    </div>



  </div>

</div>
