import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from '../../../../services/error/error-handler.service';
import { DataExportService } from '../../../../services/export/data-export.service';
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";
import {ResponseDto} from "../../../../models/dto/response/responseDto";
import {EntryResponseDto, ItemResponseDto} from "../../../../models/dto/response/itemResponseDto";
import {ExportRequestDto} from "../../../../models/dto/export/exportRequestDto";

export interface JobDownloadDto {
  exportRequest: ExportRequestDto
  population: string;
  uri: string;

}
@Component({
  selector: 'app-data-export-download',
  templateUrl: './data-export-download.component.html',
  styleUrls: ['./data-export-download.component.scss']
})
export class DataExportDownloadComponent implements OnInit {
  public id?: string;
  public fetching = false;
  public downloadDto: JobDownloadDto | undefined;
  constructor(
    private dataExportService: DataExportService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private log: ILoggingService
  ) { }
    ngOnInit(): void {
      this.fetching = true;
      this.route.url.subscribe(
        val => {
          this.id = val[2].path;
          this.dataExportService.sendExportDownload(this.id!)
            .subscribe({
              next: (value: JobDownloadDto) => {
                this.downloadDto = value;
                this.fetching = false;
            }, error: (value: HttpErrorResponse) => {
                this.log.error(`Could not fetch file: ${value.error}`)
                this.fetching = false;
              }
        });
        });
    }


  private handleExportResponse(response: any) {
    console.log(response);
    // @ts-ignore
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    //const filename = `${this.selectedTable?.target}_results_${new Date().toUTCString()}.csv`
    const date = new Date();
    const day = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;
    const time = `${date.toLocaleTimeString()}`.replace("_", "-").replace(" AM", "").replace(" PM", "").trim();
    const filename = `export_results-${day}T${time}.csv`
    if (filename)
      downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode!.removeChild(downloadLink);
  }

}
