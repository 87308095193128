import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILoggingService} from "../../../../../../services/logging/logging.service.interface";
import {Observable, Subscription} from "rxjs";
import {OrganizationDto} from "../../../../../../models/dto/user/organization/organizationDto";
import {UserOrganizationService} from "../../../../../../services/user/organization/user-organization.service";
import {UserProfileDto} from "../../../../../../models/dto/user/userProfileDto";
import {OrganizationManagementDto} from "../../../../../../models/dto/user/organization/organizationManagementDto";
import {ResponseDto} from "../../../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../../services/error/error-handler.service";
import {MatDialog} from "@angular/material/dialog";
import {UpdateOrganizationDetailsComponent} from "./update-organization-details/update-organization-details.component";

@Component({
  selector: 'app-single-organization-administration',
  templateUrl: './single-organization-administration.component.html',
  styleUrls: ['./single-organization-administration.component.scss']
})
export class SingleOrganizationAdministrationComponent implements OnInit {
  private id: string | undefined;

  public organization: OrganizationDto | undefined;
  public members: Observable<Array<UserProfileDto>> | undefined;
  public subs: Array<Subscription> | undefined;
  public saving: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private log: ILoggingService,
    private router: Router,
    private organizationService: UserOrganizationService,
    private errorHandler: ErrorHandlerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.subs = new Array<Subscription>();
    this.route.params.subscribe(res => {
      this.id = res["id"];
      this.organizationService.isUserAdministrator(this.id!).subscribe( {
        next: (value: boolean) => {
          if (!value) {
            this.router.navigate(['/forbidden']);
          } else {
            this.loadOrganization();
          }

        }
      })

    });
  }

  private loadOrganization() {
    this.organizationService.getOrganization(this.id!).subscribe( res => {
      this.organization = res;
    });
    this.members = this.organizationService.getMembersForOrganization(this.id!);
  }
  deleteOrganization() {
    this.organizationService.deleteOrganization(this.organization!.id).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this.log.error(`${this.organization!.name} could not be deleted. : ${response.errorMessages.join(', ')}`, true);

        }
        else {
          this.log.info(`${this.organization!.name} deleted.`, true)
        }
      },
      error: (err: HttpErrorResponse) => {

        this.errorHandler.handleRequestError("Delete Organization", err);
      }
    })
  }

  leaveOrganization() {
    this.organizationService.leaveOrganization(this.organization!.id).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this.log.error(`Could not resign from ${this.organization!.name}: ${response.errorMessages.join(', ')}`, true);

        }
        else {
          this.log.info(`Resigned from ${this.organization!.name}`, true);
          this.router.navigate(['administration']);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Resign from Organization", err);
      }
    })
  }

  getOrgLink(organization: OrganizationDto) {
    return `/organization/profile/${organization.id}`;
  }

  updateOrganizationDetails() {
    const ref = this.dialog.open(UpdateOrganizationDetailsComponent, {
      data: this.organization
    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        this.saving = true;
        this.organizationService.updateOrganization(res).subscribe({
          next: (value) => {
            this.log.info(`Updated ${res.name}`);
            this.saving = false;
          }, error: (value) => {
            this.log.error(`Could not update ${res.name}: ${value.message}`)
            this.saving = false;
          }
        })
      }
    })
  }
}
