import {Component, OnInit} from '@angular/core';
import {PopulationService} from "../../../../../services/population/population.service";
import {JoinRequestDto} from "../../../../../models/dto/organization/organizationJoinRequestDto";
import {ActivatedRoute} from "@angular/router";
import {ItemResponseDto} from "../../../../../models/dto/response/itemResponseDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {MatDialog} from "@angular/material/dialog";
import {
  OrganizationJoinRequestResponseComponent
} from "../../organizations/organization-join-request-response/organization-join-request-response.component";
import {ResponseDto} from "../../../../../models/dto/response/responseDto";

@Component({
  selector: 'app-population-membership-requests',
  templateUrl: './population-membership-requests.component.html',
  styleUrls: ['./population-membership-requests.component.scss']
})
export class PopulationMembershipRequestsComponent implements OnInit {
  public joinRequests: Array<JoinRequestDto> = []
  constructor(
    private populationService: PopulationService,
    private route: ActivatedRoute,
    private log: ILoggingService,
    private dialog: MatDialog
  ) {
  }

  respondToRequest(request: JoinRequestDto) {
    const ref = this.dialog.open(OrganizationJoinRequestResponseComponent, {
      data: request
    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        this.populationService.respondToPopulationJoinRequest(res).subscribe({
          next: (value: ResponseDto) => {
            if (value.successful) {
              if (res.approved) {
                this.log.info(`${request.user.firstName} ${request.user.lastName} has been accepted to the population and will be informed.`)
              } else {
                this.log.info(`The request from ${request.user.firstName} ${request.user.lastName} has been rejected. They will be informed of this response.`)
              }
            }


          }
        })
      }
    })
  }


  getMemberLink(member: UserProfileDto) {
    return `/users/profile/${member.id}`;
  }


  ngOnInit(): void {
    this.route.params.subscribe(res => {
      if (res["id"] !== undefined) {
        this.populationService.getPopulationJoinRequests(res["id"]).subscribe({
          next: (value: ItemResponseDto<Array<JoinRequestDto>>) => {
            if (value.successful) {
              this.joinRequests = value.item ?? []
            } else {
              this.log.error(`Could not get join requests: ${value.errorMessages.join(', ')}`)
            }
          }, error: (value: HttpErrorResponse) => {
            this.log.error(`Could not get join requests: ${value.message}`)
          }
        })
      }
    })
    }


}
