import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpParams} from "@angular/common/http";
import {AnimalDto} from "../../models/dto/animal/animalDto";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {AnimalProfileDto} from "../../models/dto/animal/animalProfileDto";
import {ExtractedImage} from "../../models/dto/inference/verificationTaskDto";
import {AnimalRelationshipDto} from "../../models/dto/animal/animalRelationshipDto";
import {AnimalRelationshipTypeDto} from "../../models/dto/animal/animalRelationshipTypeDto";
import {ResponseDto} from "../../models/dto/response/responseDto";
import {AnimalImageDto} from "../../models/dto/animal/animalImageDto";
import {AnimalProfileImageDto} from "../../models/dto/animal/animalProfileImageDto";
import {CoOccurrenceDto} from "../../models/dto/animal/CoOccurrenceDto";
import {AnimalSearchDto} from "../../models/dto/animal/animalSearchDto";
import {AnimalMergeDto} from "../../models/dto/animal/animalMergeDto";
import {
  CoOccurrenceReport
} from "../../app/fin-print/animal-profile/profile/profile-relationship-information/profile-relationship-information.component";

export class Node {
  public value?: AnimalDto | undefined;
  public children?: Array<Node> | undefined;
  constructor(value: AnimalDto) {
    this.value = value;
    this.children = new Array<Node>();
  }


}

export class HierarchyTree {
  private root?: Node | undefined;
  constructor() {
  }

  public getMother(animal: AnimalDto): string | undefined {
    if (animal.identifier.length == 4) {
      return undefined;
    }
    return animal.identifier.substring(0, animal.identifier.length - 1);
  }

  public insert(animal: AnimalDto) {
    if (this.root === undefined) {
      this.root = new Node(animal);
    } else {
      const node = this.root;
      const searchTree = (node: Node) => {
        if (this.getMother(animal) == node.value?.identifier) {
          node.children!.push(new Node(animal));
        }
        else if (node.children!.length > 0) {
          for (let child of node.children!) {
            searchTree(child);
          }
        }
      }
      return searchTree(node!);

    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnimalService {

  constructor(private httpClient: HttpClient) { }

  public searchAnimals(dto: AnimalSearchDto) {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/search/${dto.populationId}`;
    return this.httpClient.post<Array<AnimalDto>>(url, dto);
  }

  public sortFamilies(animals: Array<AnimalDto>): Array<AnimalDto> {
    animals = animals.sort((a, b) =>  a.identifier.length - b.identifier.length).sort( (a, b) => a.identifier.localeCompare(b.identifier));
    this.buildTrees(animals);
    animals = animals.filter(a => a.identifier.toLowerCase() !== "garbage");
    return animals;
  }

  public getMatrilineMap(animals: Array<AnimalDto>): Map<string, Array<AnimalDto>> {
    const matrilines = new Map<string, Array<AnimalDto>> ();
    for (let animal of animals) {
      const matriline = this.getMatriline(animal);
      if (!matrilines.has(matriline)) {
        matrilines.set(matriline, new Array<AnimalDto>());
      }
      const current = matrilines.get(matriline) as Array<AnimalDto>;
      current.push(animal);
      matrilines.set(matriline, current)
    }
    return matrilines;
  }


  public buildTrees(animals: Array<AnimalDto>) {
    const mM = this.getMatrilineMap(animals);
    for (let m of mM.keys()) {
      let tree: HierarchyTree = new HierarchyTree();
      for (let a of mM.get(m)!) {
        tree.insert(a);
      }

    }
  }


  public getMother(animal: AnimalDto): string | undefined {
    if (animal.identifier.length == 4) {
      return undefined;
    }
    return animal.identifier.substring(0, animal.identifier.length - 1);
  }

  public getMatriline(animal: AnimalDto): string {
    return animal.identifier.substring(0, 4);
  }

  public getChildren(animal: AnimalDto, animals: Array<AnimalDto>): Array<AnimalDto> {
    let children = [] as Array<AnimalDto>;
    for (let child of animals) {
      if (this.getMother(child) == animal.identifier) {
        children.push(child);
      }
    }

    return children;
  }
  /*Not Used*/
  public getAnimals(): Observable<Array<AnimalDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  public getNextAnimal(animalId: string, populationId: string, status: string = "alive"): Observable<AnimalDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getNextAnimal}/${status}/${populationId}/${animalId}`;
    return this.httpClient.get<AnimalDto>(url);
  }
  public getPreviousAnimal(animalId: string, populationId: string, status: string = "alive"): Observable<AnimalDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getPreviousAnimal}/${status}/${populationId}/${animalId}`;
    return this.httpClient.get<AnimalDto>(url);
  }

  public getPopulationAnimals(populationId: string): Observable<Array<AnimalDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/population/${populationId}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  public getAnimalsComplete(populationId: string, status: string = "alive"): Observable<Array<AnimalDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalsComplete}/${status}/${populationId}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  public getAnimalPreviews(populationId: string, status: string = "alive", hasProfile: boolean = true): Observable<Array<AnimalDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/preview/${status}/${populationId}/${hasProfile}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  public getAnimalPreviewThumbnail(animalId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/profile/preview/img/${animalId}`;
    return this.httpClient.get(url);

  }

  public getAnimalProfile(animalId: string): Observable<AnimalProfileDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalProfile}/${animalId}`;
    return this.httpClient.get<AnimalProfileDto>(url);
  }

  public getAnimalImages(animalId: string): Observable<Array<AnimalImageDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalImages}/${animalId}`;
    return this.httpClient.get<Array<AnimalImageDto>>(url);
  }

  public getExtractedAnimalImages(animalId: string): Observable<Array<ExtractedImage>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getExtractedAnimalImages}/${animalId}`;
    return this.httpClient.get<Array<ExtractedImage>>(url);
  }

  public getAnimalProfileImages(animalId: string): Observable<Array<AnimalProfileImageDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalProfileImages}/${animalId}`;
    return this.httpClient.get<Array<AnimalProfileImageDto>>(url);
  }

  public updateAnimalRelationship(relationshipUpdate: AnimalRelationshipDto): Observable<ResponseDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.updateRelationship}`;
    return this.httpClient.post<ResponseDto>(url, relationshipUpdate);
  }

  public getRelationshipTypes(): Observable<Array<AnimalRelationshipTypeDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getRelationshipTypes}`;
    return this.httpClient.get<Array<AnimalRelationshipTypeDto>>(url);
  }

  public updateAnimalDetails(animalProfileDto: AnimalProfileDto): Observable<AnimalProfileDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalProfile}`;
    return this.httpClient.put<AnimalProfileDto>(url, animalProfileDto);
  }

  public getMainAnimalImage(animalId: string): Observable<AnimalImageDto> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getMainImage}/${animalId}`;
    return this.httpClient.get<AnimalImageDto>(url);
  }

  public mergeAnimalProfiles(dto: AnimalMergeDto): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/merge`;
    return this.httpClient.post<any>(url, dto);
  }

  public getMainAnimalImages(animalId: string): Observable<Array<AnimalImageDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getMainImages}/${animalId}`;
    return this.httpClient.get<Array<AnimalImageDto>>(url);
  }

  public getExtractedImageById(extractedImageId: string | undefined, size: string = "tmb"): Observable<ExtractedImage> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getExtractedImageById}/${extractedImageId}?size=${size}`;
    return this.httpClient.get<ExtractedImage>(url);
  }

  public updateAnimalProfileImage(animalId: string, profileImage: AnimalProfileImageDto): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.profileImage}/${animalId}`;
    return this.httpClient.put<ExtractedImage>(url, profileImage);
  }

  public removeAnimalProfileImage(animalId: string, profileImageId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.profileImage}/${animalId}/${profileImageId}`;
    return this.httpClient.delete<ExtractedImage>(url);
  }

  public getAnimalCoOccurrences(animalId: string): Observable<CoOccurrenceReport> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getTopCoOccurrences}/${animalId}`;
    return this.httpClient.get<CoOccurrenceReport>(url);
  }

  public getTopCoOccurrencesForYear(animalId: string, year: number, count: number = 3): Observable<Array<{animal: AnimalDto, coOccurrence: number}>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getTopCoOccurrences}/${animalId}/${year}/${count}`;
    return this.httpClient.get<Array<{animal: AnimalDto, coOccurrence: number}>>(url);
  }

  public getSidedImages(animalId: string, side: string, count: number = 5): Observable<Array<AnimalImageDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getMainSidedImage}/${animalId}/${side}/${count}`;
    return this.httpClient.get<Array<AnimalImageDto>>(url);
  }

  public setSidedImage(animalId: string, side: string, animalImageId: string): Observable<Array<AnimalImageDto>> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getMainSidedImage}/${animalId}/${side}/${animalImageId}`;
    return this.httpClient.put<Array<AnimalImageDto>>(url, {});
  }


  mapQuality(quality: number | undefined): string {
    if (quality == 0 || quality == undefined || quality > 4) {
      return "Unknown"
    } else if (quality == 1 || quality == 2) {
      return "Noteworthy"
    } else if (quality == 3) {
      return "Good"
    }
    return "Excellent";
  }

  getAnimalImageByAnnotation(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimalImages}/annotation/${id}`;
    return this.httpClient.get<AnimalImageDto>(url);
  }

  getAnimalsPreview(id: string, status: string): Observable<Array<AnimalDto>>  {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/preview/${status}/${id}`;
    return this.httpClient.get<Array<AnimalDto>>(url);
  }

  concatIds(res: Array<AnimalDto>): Array<AnimalDto> {
    const arr: Array<AnimalDto> = new Array<AnimalDto>()
    for (let a of res) {
      a.identifier = this.getDisplayIdentifier(a);
      arr.push(a);
    }
    return arr;
  }

  getDisplayIdentifier(a: AnimalDto): string {
    return a.identifier;
  }

  deleteAnimal(id: string) {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.getAnimals}/${id}`;
    return this.httpClient.delete<ResponseDto>(url);
  }

  public changePopulation(animalId: string, populationId: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.updatePopulation}/${animalId}/${populationId}`;
    return this.httpClient.get(url);
  }

  public getAnimalImageBlobFromAnnotation(annotationId: string, size?: string): Observable<any> {
    return this.getAnimalImageBlob(annotationId, undefined, undefined, size);
  }

  public getAnimalImageBlobFromExtractedImage(extractedImageId: string, size?: string): Observable<any> {
    return this.getAnimalImageBlob(undefined, extractedImageId, undefined, size);
  }

  public getAnimalImageBlobFromAnimalImage(animalImageId: string, size?: string): Observable<any> {
    return this.getAnimalImageBlob(undefined, undefined, animalImageId, size);
  }

  private getAnimalImageBlob(annotationId?: string, extractedImageId?: string, animalImageId?: string, size?: string): Observable<any> {
    const url = `${environment.server.baseUrl}${environment.server.api.animal.blob}`;
    const params: any = {}

    if (annotationId !== undefined) {
      params["annotationId"] = annotationId!;
    }
    if (extractedImageId !== undefined) {
      params["extractedImageId"] = extractedImageId!;
    }
    if (animalImageId !== undefined) {
      params["animalImageId"] = animalImageId!;
    }
    if (size !== undefined) {
      params["size"] = size
    }
    return this.httpClient.get(url, {params: params})

  }
}
