import {Component, Input, OnInit} from '@angular/core';
import {OrganizationDto} from "../../../../../models/dto/user/organization/organizationDto";
import {Observable, of} from "rxjs";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {OrganizationManagementDto} from "../../../../../models/dto/user/organization/organizationManagementDto";
import {ResponseDto} from "../../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {UserOrganizationService} from "../../../../../services/user/organization/user-organization.service";
import {PopulationService} from "../../../../../services/population/population.service";
import {UserManagementService} from "../../../../../services/user/management/user-management.service";
import {UserContentService} from "../../../../../services/user/content/user-content.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {UserDto} from "../../../../../models/dto/response/user/management/userDto";
import {UntypedFormControl} from "@angular/forms";
import {
  OrganizationUserAddDialogComponent
} from "../organization-user-add-dialog/organization-user-add-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {JoinRequestDto} from "../../../../../models/dto/organization/organizationJoinRequestDto";
import {ItemResponseDto} from "../../../../../models/dto/response/itemResponseDto";
import {
  OrganizationJoinRequestResponseComponent
} from "../organization-join-request-response/organization-join-request-response.component";

@Component({
  selector: 'app-organization-members-component',
  templateUrl: './organization-members-component.component.html',
  styleUrls: ['./organization-members-component.component.scss']
})
export class OrganizationMembersComponentComponent implements OnInit {

  @Input() organization: OrganizationDto | undefined;
  @Input() membersObs: Observable<Array<UserProfileDto>> | undefined;

  public organizations: Observable<Array<OrganizationDto>> | undefined;
  public creatingNewOrganization = false;
  public newOrganizationName = "";
  public user: UserProfileDto | undefined;
  public newOrganizationMembers = new UntypedFormControl('');
  public currentlySelectedOrganization: OrganizationDto | undefined;
  public currentlySelectedUser: UserProfileDto | undefined;
  public possibleOrganizationMembers: Map<string, Observable<Array<UserDto>>> = new Map<string, Observable<Array<UserDto>>>();
  public joinRequests: Array<JoinRequestDto> = [];

  private admins: Array<UserProfileDto> | undefined;
  private users: Array<UserProfileDto> | undefined;



  constructor(
    private organizationService: UserOrganizationService,
    private populationService: PopulationService,
    private userService: UserManagementService,
    private userContentService: UserContentService,
    private logService: ILoggingService,
    private errorHandler: ErrorHandlerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (!this.organization) { return;}
    this.membersObs = this.organizationService.getMembersForOrganization(this.organization.id!);
    this.organizationService.getMembersForOrganization(this.organization!.id!).subscribe({
      next: (value: Array<UserProfileDto>) => {
        this.users = value;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Fetching Organization Members", value);
      }
    });
    this.organizationService.getAdministratorsForOrganization(this.organization!.id!).subscribe({
      next: (value: Array<UserProfileDto>) => {
        this.admins = value;
      },
      error: (value: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Fetching Organization Administrators", value);
      }
    });

    this.organizationService.getOrganizationJoinRequests(this.organization!.id!).subscribe({
      next: (value: ItemResponseDto<Array<JoinRequestDto>>) => {
        if (value.successful) {
          this.joinRequests = value.item ?? [];
        } else {
          this.logService.error(`Could not get organizations: ${value.errorMessages.join(', ')}`)
        }

      }, error: (value: HttpErrorResponse) => {
        this.logService.error(`Could not get organizations: ${value.message}`)
      }
    })
  }

  toggleUserAddActive(organization: OrganizationDto) {
    this.newOrganizationMembers.setValue('');
    if (!this.possibleOrganizationMembers.has(organization.id)) {
      this.userService.getUsers().subscribe( res => {
        this.possibleOrganizationMembers.set(organization.id, of(res.users
          .filter(v => organization.members.map(u => u.email).indexOf(v.email) == -1)));
      })

    }
    organization.userAddActive = !organization.userAddActive;
  }

  isUserOrganizationAdmin(user: UserProfileDto | undefined) {
    if (user === undefined || !this.admins) return false;
    return this.admins.map( v => v.email).indexOf(user.email) !== -1;
  }

  promoteUser(user: UserProfileDto) {
    const dto: OrganizationManagementDto = {
      organizationId: this.organization!.id,
      userEmail: user.email
    };
    this.organizationService.promoteUserToAdministrator(dto).subscribe({
      next: (response: ResponseDto) => {
        this.logService.info(`${user.firstName} ${user.lastName} promoted to organization administrator`, true)
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("User Promotion", err);
      }
    });
  }

  demoteUser(user: UserProfileDto) {
    const dto: OrganizationManagementDto = {
      organizationId: this.organization!.id,
      userEmail: user.email
    };
    this.organizationService.demoteUserFromAdministrator(dto).subscribe({
      next: (response: ResponseDto) => {
        this.logService.info(`${user.firstName} ${user.lastName} demoted from organization administrator`, true);
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("User Demotion", err);
      }
    });
  }

  addUserToOrganization(organization: OrganizationDto, userEmail: string) {
    const orgDto: OrganizationManagementDto = {
      organizationId: organization.id,
      userEmail: userEmail
    };

    this.organizationService.addUserToOrganization(orgDto).subscribe( {
      next: (response: ResponseDto) => {
        this.logService.info(`${userEmail} added to organization.`, true);
        this.ngOnInit();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Organization User Addition", err);
      }
    })
  }

  addUsersToOrganization(newUsers: Array<string>) {
    for (let email of newUsers) {
      this.addUserToOrganization(this.organization!, email);
    }
  }

  createOrganization() {
    const orgName = this.newOrganizationName;
    const members = this.newOrganizationMembers.value;
    const orgDto: OrganizationManagementDto = {
      organizationId: orgName,
    }
  }

  removeUserFromOrganization(user: UserProfileDto) {
    const orgDto: OrganizationManagementDto = {
      organizationId: this.organization!.id,
      userEmail: user.email
    }
    this.organizationService.removeUserFromOrganization(orgDto).subscribe({
      next: (response: ResponseDto) => {
        if (!response.successful) {
          this.logService.error(`${user.firstName} ${user.lastName} could not be removed from ${this.organization!.name}: ${response.errorMessages.join(', ')}`, true);

        }
        else {
          this.logService.info(`${user.firstName} ${user.lastName} removed from ${this.organization!.name}`, true)
          this.ngOnInit();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleRequestError("Remove User", err);
      }
    })
  }



  loadOrganization(organization: OrganizationDto) {

  }

  loadMember(member: UserProfileDto) {

  }

  addMembers() {
    const ref = this.dialog.open(OrganizationUserAddDialogComponent, {
      data: {
        currentMembers: this.users?.concat(this.admins!),
        allUsersObs: this.userService.getUserDtos()
      },
      width: "500px"
    });
    ref.afterClosed().subscribe( res => {
      if (res) {
        this.addUsersToOrganization(res);
      }
    })
  }

  getMemberLink(member: UserProfileDto) {
    return `/users/profile/${member.id}`;
  }

  respondToRequest(request: JoinRequestDto) {
    request.itemDto = this.organization!
    const ref = this.dialog.open(OrganizationJoinRequestResponseComponent, {
      data: request
    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        this.organizationService.respondToOrganizationJoinRequest(res).subscribe({
          next: (value: ResponseDto) => {
            if (value.successful) {
              if (res.approved) {
                this.logService.info(`${request.user.firstName} ${request.user.lastName} has been accepted to the organization and will be informed.`)
              } else {
                this.logService.info(`The request from ${request.user.firstName} ${request.user.lastName} has been rejected. They will be informed of this response.`)
              }
            }


          }
        })
      }
    })
  }
}
