import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {throwError, Observable, of} from 'rxjs';
import { retryWhen, mergeMap, delay, scan } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestClientService {

  private maxRetries = 5;

  constructor(private http: HttpClient) {}

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url).pipe(
      retryWhen(errors => this.exponentialBackoff(errors))
    );
  }

  // Exponential backoff function
  private exponentialBackoff(errors: Observable<any>): Observable<any> {
    return errors.pipe(
      mergeMap((error: HttpErrorResponse, retryAttempt: number) => {
        // Retry only if it's a 503 error
        if (error.status === 503) {
          const backoffDelay = 1000 * Math.pow(2, retryAttempt); // Exponential backoff

          // Stop retrying if max retries exceeded
          if (retryAttempt < this.maxRetries) {
            console.log(`Attempt ${retryAttempt + 1}: retrying in ${backoffDelay}ms`);
            return of(error).pipe(delay(backoffDelay));
          }
        }

        // If error is not 503 or max retries exceeded, throw the error
        return throwError(error);
      })
    );
  }
}
