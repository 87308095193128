<h1 mat-dialog-title>Update Details for {{data.name}}</h1>


<div mat-dialog-content>
<!--  <mat-form-field>-->
<!--    <mat-label>Organization Name</mat-label>-->
<!--    <input type="text" [(ngModel)]="data.name" />-->
<!--  </mat-form-field>-->
  <div class="organization-item">
    <mat-checkbox [(ngModel)]="data.researchOrganization">Is this a research organization?</mat-checkbox>
  </div>

</div>

<div class="button-row">
  <button mat-stroked-button (click)="submit()">Save</button>
  <button mat-stroked-button (click)="onNoClick()">Cancel</button>
</div>
