import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AnnotatedImage} from "../../../../../models/items/files/images/annotated-images/annotated-image.model";
import {ImageAnnotation} from "../../../../../models/annotation/image/annotation.model";
import {PopulationService} from "../../../../../services/population/population.service";
import {MatDialog} from "@angular/material/dialog";
import {AnnotationProfileComponent} from "../annotation-profile/annotation-profile.component";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../../services/error/error-handler.service";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";

@Component({
  selector: "annotation-list",
  templateUrl: "./annotation-list.component.html",
  styleUrls: ["./annotation-list.component.scss"]
})
export class AnnotationListComponent implements OnInit {
  @Input() image!: AnnotatedImage;
  @Output() annotationRemoved: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() annotationConfirmed: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() updateAnnotation: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() highlightAnnotation: EventEmitter<ImageAnnotation> = new EventEmitter<ImageAnnotation>();
  @Output() removeHighlights: EventEmitter<any> = new EventEmitter<any>();

  canEdit = false;
  canSetProfile = false;


  constructor(
    private populationService: PopulationService,
    private workspaceService: WorkspaceService,
    private dialog: MatDialog,
    private animalService: AnimalService,
    private log: ILoggingService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.workspaceService.workspace.subscribe( res => {
      this.canEdit = (res.settings?.populationRoles?.administrator || res.settings?.populationRoles?.expert || res.settings?.populationRoles?.professional) ?? false;
      this.canSetProfile = (res.settings?.populationRoles?.administrator || res.settings?.populationRoles?.professional) ?? false;
    })
    // this.populationService.populationRoles.subscribe( res => {
    //   this.canEdit = res.expert! || res.administrator! || res.professional!;
    //   this.canSetProfile = res.administrator! || res.professional!;
    // });
  }

  public deleteBox(uuid: string): void {
    const idx = this.image.annotations!.map( (annotation: ImageAnnotation) => {return annotation.id}).indexOf(uuid);
    const annotation: ImageAnnotation = this.image.annotations[idx];
    this.image.annotations!.splice(idx, 1);
    this.annotationRemoved.emit(annotation);
  }

  public confirm(uuid: string): void {
    const idx = this.image.annotations!.map( (annotation: ImageAnnotation) => {return annotation.id}).indexOf(uuid);
    const annotation: ImageAnnotation = this.image.annotations[idx];
    this.annotationConfirmed.emit(annotation);
  }

  public update(uuid: string): void {
    const idx = this.image.annotations!.map( (annotation: ImageAnnotation) => {return annotation.id}).indexOf(uuid);
    const annotation: ImageAnnotation = this.image.annotations[idx];
    annotation.confirmed = true;
    this.updateAnnotation.emit(annotation);
  }

  public activateAnnotationHighlighting(imageAnnotation: ImageAnnotation) {
    this.removeAnnotationHighlights(false);
    imageAnnotation.highlight = true;
    this.highlightAnnotation.emit(imageAnnotation);
  }

  public removeAnnotationHighlights(emit: boolean) {
    for (let annotation of this.image.annotations) {
      annotation.highlight = false;
    }
    if (emit) {
      this.removeHighlights.emit();
    }
  }

  useForProfile(annotation: ImageAnnotation) {
    const ref = this.dialog.open(AnnotationProfileComponent, {
      data: {
        image: this.image,
        annotation: annotation,
        side: this.image?.metaData?.side?.toLowerCase()
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res && res.side && res.animalImage) {
        this.animalService
          .setSidedImage(res.animalImage.animalId, res.side, res.animalImage.id)
          .subscribe({
            next: (value: any) => {
              this.log.info(`Profile Image for ${res.animalImage.identifier} (${res.side} side) set successfully`)
            }, error: (value: HttpErrorResponse) => {
              this.errorHandler.handleRequestError("Setting profile image", value);
            }
        })
      } else {
        this.log.info("Cancelling profile picture assignment.")
      }
    })
  }
}
