<div class="content-container" *ngIf="organization">
  <div class="content-header">
    <div class="organization-name">
      <h1 [routerLink]="getOrgLink(organization)">{{organization.name}}</h1>
      <h4 *ngIf="organization.researchOrganization">RESEARCH ORGANIZATION</h4>
    </div>

    <div class="danger-buttons">
      <button mat-stroked-button color="accent" (click)="updateOrganizationDetails()">Update Details</button>
      <button mat-stroked-button color="warn" (click)="leaveOrganization()">Resign from {{organization.name}}</button>
      <button mat-stroked-button color="warn" (click)="deleteOrganization()">Delete {{organization.name}}</button>
    </div>
  </div>

  <div class="content">
    <app-permit-management [organization]="organization"></app-permit-management>
  </div>
  <div class="content">
    <app-organization-members-component [organization]="organization" [membersObs]="members"></app-organization-members-component>
<!--    <app-encounter-timeline [organizationId]="organization.id"></app-encounter-timeline>-->

  </div>
  <app-area-loading [criteria]="!saving"></app-area-loading>
</div>
