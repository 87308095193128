import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrganizationCreationDto } from '../../../../models/dto/organization/organizationCreationDto';
import { CreateOrganizationResponseDto } from '../../../../models/dto/user/organization/createOrganizationDto';
import { organizationPreviewDto } from '../../../../models/dto/user/organization/organizationPreviewDto';
import { UserProfileDto } from '../../../../models/dto/user/userProfileDto';
import { ILoggingService } from '../../../../services/logging/logging.service.interface';
import { UserManagementService } from '../../../../services/user/management/user-management.service';
import { UserOrganizationService } from '../../../../services/user/organization/user-organization.service';
import { OrganizationCreationDialogComponent } from '../../administration/organizations/organization-creation-dialog/organization-creation-dialog.component';

@Component({
  selector: 'app-organization-profile-list',
  templateUrl: './organization-profile-list.component.html',
  styleUrls: ['./organization-profile-list.component.scss']
})
export class OrganizationProfileListComponent {

  public allOrganizationsPreviews: Array<organizationPreviewDto> = [];
  public loadedAllPreviews: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserManagementService,
    private log: ILoggingService,
    private organizationservice: UserOrganizationService
  ) { }

  ngOnInit(): void {
    this.allOrganizationsPreviews = new Array<organizationPreviewDto>();
    this.getAllOrganizations();
  }
  createOrganization() {
    const ref = this.dialog.open(OrganizationCreationDialogComponent,
      {
        data:
          { name: "", allUsers: this.userService.getUserDtos(), users: new Array<UserProfileDto>() },
        width: "500px"
      })
    ref.afterClosed().subscribe(res => {
      const dto: OrganizationCreationDto = { users: res.users, name: res.name, researchOrganization: res.researchOrganization};

      this.organizationservice.createNewOrganization(dto).subscribe({
        next: (value: CreateOrganizationResponseDto) => {
          this.log.info(`${dto.name} created!`);
          this.getAllOrganizations();
          /*this.organizationservice.getUserOrganizations().subscribe(res => {
            this.organizations = res;
            this.selectedOrganizations.setValue([value.id]);
            this.announceUpdates();

          })*/
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create organization: ${value.message}`)
        }
      })
    })

  }

  getAllOrganizations() {
    this.organizationservice.getAllOrganizationPreviews().subscribe({
      next: (value: Array<organizationPreviewDto>) => {
        this.allOrganizationsPreviews = value;
        this.loadedAllPreviews = true;

      },
      error: (err: any) => { }
    });
  }
}
