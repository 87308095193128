import {Component, OnInit} from '@angular/core';
import {AnimalService} from "../../../../services/animal/animal.service";
import {AnimalDto} from "../../../../models/dto/animal/animalDto";
import {ActivatedRoute} from "@angular/router";
import {A} from "@angular/cdk/keycodes";
import {ResponseDto} from "../../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "../../../../services/error/error-handler.service";
import {ILoggingService} from "../../../../services/logging/logging.service.interface";

@Component({
  selector: 'app-population-individuals',
  templateUrl: './population-individuals.component.html',
  styleUrls: ['./population-individuals.component.scss']
})
export class PopulationIndividualsComponent implements OnInit {

  public animals?: Array<AnimalDto>
  constructor(
    private animalService: AnimalService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private log: ILoggingService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(res => {
      if (res["id"] !== undefined) {
        this.animalService.getAnimalPreviews(res["id"], "all", true).subscribe( res => {
          this.animals = res;
          for (let animal of this.animals) {
            animal.primaryIdentifier = animal.identifier.split(" / ")[0];
            animal.alternateIdElements = animal.alternateIds?.map(a => {
              return {id: animal.id, identifier: a}});
          }
        })
      }
    })

  }

  save(animal: AnimalDto) {
      animal.alternateIds = animal.alternateIdElements?.filter(a => a.identifier.length > 0).map(a => a.identifier);
      animal.editing = false;
      animal.identifier = animal.primaryIdentifier;
      this.animalService.updateAnimalDetails(animal).subscribe(res => {})
  }

  addAlternateId(animal: AnimalDto) {
    if (animal.alternateIdElements === undefined) {
      animal.alternateIdElements = new Array();
    }
    animal.alternateIdElements.push({id: animal.id, identifier: ""})

  }

  deleteAnimal(animal: AnimalDto) {
    this.animalService.deleteAnimal(animal.id).subscribe({
    next: (value: ResponseDto) => {
      if (value.successful) {
        this.log.info(`Successfully deleted ${animal.identifier}`);
        const idx = this.animals?.findIndex(a => a.id == animal.id);
        if (idx !== undefined) {
          this.animals?.splice(idx, 1);
        }
      } else {
        this.log.error(`Could not delete ${animal.identifier}: ${value.errorMessages.join(', ')}`);
      }
    },
      error: (value: HttpErrorResponse) => {
      this.errorHandler.handleRequestError(`Deleting ${animal.identifier}`, value);
      }
    })
  }

  getProfileLink(animal: AnimalDto) {
    return `individuals/${animal.id}`;
  }
}
