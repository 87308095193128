import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PopulationDto} from "../../../../models/dto/population/populationDto";
import {BehaviorService} from "../../../../services/behavior/behavior.service";
import {UntypedFormControl} from "@angular/forms";
import {AnimalBehaviorDto} from "../../../../models/dto/behavior/animalBehaviorDto";
import {WorkspaceService} from "../../../../services/workspace/workspace.service";

@Component({
  selector: 'app-submission-behavior',
  templateUrl: './submission-behavior.component.html',
  styleUrls: ['./submission-behavior.component.scss']
})
export class SubmissionBehaviorComponent implements OnInit, OnChanges {

  @Input() population?: PopulationDto
  @Output() behaviorsUpdated: EventEmitter<Array<AnimalBehaviorDto>> = new EventEmitter<Array<AnimalBehaviorDto>>();
  @Input() selectedBehaviors?: Array<AnimalBehaviorDto> = new Array<AnimalBehaviorDto>();
  public behaviors?: Array<AnimalBehaviorDto>;
  constructor(
    private behaviorService: BehaviorService,
    private workspaceService: WorkspaceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['behaviors'] || changes['selectedBehaviors']) {
      this.setSelectedBehaviors();
    }
  }

  ngOnInit() {

    if (!this.population || !this.population.id) {
      this.workspaceService.workspace.subscribe(res => {
        this.population = res.settings?.population;
        this.getBehaviors(this.population?.id);

      });
    } else {
      this.getBehaviors(this.population.id)
    }
  }

  updateBehaviors() {
    this.behaviorsUpdated.emit(this.selectedBehaviors)
  }

  getBehaviors(populationId?: string) {
    if (!populationId) {
      return;
    }

    this.behaviorService.getPopulationBehaviors(populationId).subscribe({
      next: (value: any) => {
        this.behaviors = value;
        this.setSelectedBehaviors();
      }
    })
  }

  private setSelectedBehaviors() {
    if (!this.behaviors || !this.selectedBehaviors) return;

    // Ensure selectedBehaviors has exact references from behaviors
    this.selectedBehaviors = this.selectedBehaviors
      .map(selected => this.behaviors?.find(behavior => behavior.id === selected.id))
      .filter(behavior => behavior !== undefined) as Array<AnimalBehaviorDto>;
  }
}
